import { Company } from '../../../company';
import { Tour } from '../../../tour/entities';

const tourMessageList = [
  { messageType: 'tour.Tour', topic: 'tour' },
  { messageType: 'tour.TourStatusV2', topic: 'tour' },
  { messageType: 'tour.TourEmissionsCalculated', topic: 'tour' },
  { messageType: 'tour.TourEmissionsReceived', topic: 'tour' },
  { messageType: 'tour.TourForwarded', topic: 'tour' },
  { messageType: 'tour.TourUnforwarded', topic: 'tour' },
  { messageType: 'tour.TourForwardingRejected', topic: 'tour' },
  { messageType: 'tour.TourUnforwardingRejected', topic: 'tour' },
  { messageType: 'tour.TourForwardingChainRejected', topic: 'tour' },
  { messageType: 'tour.TourForwardingChainAccepted', topic: 'tour' },
  { messageType: 'tour.TourVehicleAllocationChanged', topic: 'tour' },
  { messageType: 'tour.TourVehicleAllocationRequest', topic: 'tour' },
  { messageType: 'tour.TourVehicleAllocationRejected', topic: 'tour' },
  { messageType: 'tour.TourPlatformVersionedVehicleAllocationChanged', topic: 'tour' },
  { messageType: 'tour.TourPlacesCreated', topic: 'tour' },
  { messageType: 'tour.TourPlacesUpdated', topic: 'tour' },
  { messageType: 'tour.TourPlacesDeleted', topic: 'tour' },
  { messageType: 'tour.AssignedUserToTour', topic: 'tour' },
  { messageType: 'tour.AssigningUserToTourRejected', topic: 'tour' },
  { messageType: 'tour.TourOrderItems', topic: 'tour' },
  { messageType: 'tour.TourNote', topic: 'tour' },
  { messageType: 'tour.TourDocument', topic: 'tour' },
  { messageType: 'tour_forwarding_input.TourForwardingRequest', topic: 'tour-forwarding-input' },
  { messageType: 'tour_forwarding_input.TourUnforwardingRequest', topic: 'tour-forwarding-input' },
  { messageType: 'tour_forwarding_input.TourForwardingChainRequest', topic: 'tour-forwarding-input' },
  { messageType: 'tour_forwarding_input.RelatedTours', topic: 'tourstatus_input' },
  { messageType: 'ocean_prototype.TransportMilestones', topic: 'ocean-prototype' },
  { messageType: 'ocean_prototype.Route', topic: 'ocean-prototype' },
  { messageType: 'ocean_prototype.TransportUrlOverride', topic: 'ocean-prototype' },
  { messageType: 'ocean_prototype.Demurrage', topic: 'ocean-prototype' },
  { messageType: 'ocean_prototype.TransportAlert', topic: 'ocean-prototype' },
  { messageType: 'tour_eta.TourEtaV3', topic: 'tour-eta' },
  { messageType: 'tour_event.TourEventCreated', topic: 'tour-event' },
  { messageType: 'tour_event.TourEventUpdated', topic: 'tour-event' },
  { messageType: 'tour_event.TourEventDeleted', topic: 'tour-event' },
  { messageType: 'tour_event.StopEventCreated', topic: 'tour-event' },
  { messageType: 'tour_event.StopEventUpdated', topic: 'tour-event' },
  { messageType: 'tour_event.StopEventDeleted', topic: 'tour-event' },
  { messageType: 'tour_event.DeliveryEventCreated', topic: 'tour-event' },
  { messageType: 'tour_event.DeliveryEventUpdated', topic: 'tour-event' },
  { messageType: 'tour_event.DeliveryEventDeleted', topic: 'tour-event' },
  { messageType: 'tour_events_input.ExternalEtaReceived', topic: 'tour-events-input' },
  {
    messageType: 'place_input.TourGeocodingResultReceived',
    topic: 'place-input',
    keyFn: (tour: Tour) => `tour:${tour.tour_id.toString()}`,
  },
  { messageType: 'contacts.TourContacts', topic: 'contacts' },
  { messageType: 'contacts.TourDeliveryContacts', topic: 'contacts' },
  { messageType: 'etainput.RecalculateEta', topic: 'eta-input' },
  { messageType: 'etainput.EtaRouteReceived', topic: 'eta-input' },
  { messageType: 'etainput.MlEtaReceived', topic: 'eta-input' },
  { messageType: 'etainput.RelatedTourEtaCalculated', topic: 'eta-input' },
  {
    messageType: 'platform_callback.CallbackMessageV2',
    topic: 'callback',
    keyFn: (tour: Tour) => `tour:${tour.tour_id.toString()}`,
  },
  {
    messageType: 'tourinput.TourCreateOrUpdate',
    topic: 'tour-input',
    keyFn: (tour: Tour) => `${tour.platform?.platform_id}:${tour.transport_id}`,
  },
  {
    messageType: 'tourinput.TourDelete',
    topic: 'tour-input',
    keyFn: (tour: Tour) => `${tour.platform?.platform_id}:${tour.transport_id}`,
  },
  {
    messageType: 'tourinput.VehicleAllocationUpdate',
    topic: 'tour-input',
    keyFn: (tour: Tour) => `${tour.platform?.platform_id}:${tour.transport_id}`,
  },
  {
    messageType: 'tourinput.TourStatusUpdate',
    topic: 'tour-update-input',
    keyFn: (tour: Tour) => `${tour.platform?.platform_id}:${tour.transport_id}`,
  },
  {
    messageType: 'tourinput.TourUpsert',
    topic: 'tour-input',
    keyFn: (tour: Tour) => `${tour.platform?.platform_id}:${tour.transport_id}`,
  },
  {
    messageType: 'tourinput.PlatformTourForwardingChain',
    topic: 'tour-input',
    keyFn: (tour: Tour) => `${tour.platform?.platform_id}:${tour.transport_id}`,
  },
  {
    messageType: 'tourinput.CloneTour',
    topic: 'tour-input',
    keyFn: (tour: Tour) => `${tour.platform?.platform_id}:${tour.transport_id}`,
  },
  {
    messageType: 'tourinput.TourPatch',
    topic: 'tour-input',
    keyFn: (tour: Tour) => `${tour.platform?.platform_id}:${tour.transport_id}`,
  },
  {
    messageType: 'tourinput.ContainerAssignment',
    topic: 'tour-input',
    keyFn: (tour: Tour) => `${tour.platform?.platform_id}:${tour.transport_id}`,
  },
  {
    messageType: 'tourinput.TourNote',
    topic: 'tour-input',
  },
  {
    messageType: 'tourinput.TourNoteExternal',
    topic: 'tour-input',
    keyFn: (tour: Tour) => `${tour.platform?.platform_id}:${tour.transport_id}`,
  },
  { messageType: 'diagnostic.GeocodingCascadeDiagnostic', topic: 'diagnostics' },
  {
    messageType: 'billing.TransactionCreated',
    topic: 'billing',
    keyFn: (tour: Tour) => `tour:${tour.tour_id}`,
  },
];

export const companyMessageList = [
  { messageType: 'company.Company', topic: 'company' },
  { messageType: 'company.CompanyOnPlatform', topic: 'company' },
  { messageType: 'company.VehiclesDeleted', topic: 'company' },
  { messageType: 'company.VehiclesDeletionRejected', topic: 'company' },
  { messageType: 'company.CompanyIdentifier', topic: 'company' },
  { messageType: 'company.UserInCompany', topic: 'company' },
  { messageType: 'company.UserInCompanyConfigurationUpdated', topic: 'company' },
  { messageType: 'company.UserInCompanyConfigurationUpdateRejected', topic: 'company' },
  { messageType: 'company.UserAddedToCompany', topic: 'company' },
  { messageType: 'company.UserAddingToCompanyRejected', topic: 'company' },
  { messageType: 'company.UserRoleInCompanyChanged', topic: 'company' },
  { messageType: 'company.UserRoleInCompanyChangeRejected', topic: 'company' },
  { messageType: 'company.UserRemovedFromCompany', topic: 'company' },
  { messageType: 'company.UserRemovalFromCompanyRejected', topic: 'company' },
  { messageType: 'company.UserMetadataInCompanyChanged', topic: 'company' },
  { messageType: 'company.UserMetadataInCompanyChangeRejected', topic: 'company' },
  { messageType: 'company.SiteCreated', topic: 'company' },
  { messageType: 'company.SiteUpdated', topic: 'company' },
  { messageType: 'company.CreatingCompanyAssetRejected', topic: 'company' },
  { messageType: 'company.ActivatingCompanyAssetRejected', topic: 'company' },
  { messageType: 'company.CompanyAssetCreated', topic: 'company' },
  { messageType: 'company.CompanyAssetActivated', topic: 'company' },
  { messageType: 'company.CompanyUpdated', topic: 'company' },
  { messageType: 'company.UpdatingCompanyRejected', topic: 'company' },
  { messageType: 'company.CompanyOnPlatformCreated', topic: 'company' },
  { messageType: 'company.CompanyOnPlatformDeleted', topic: 'company' },
  { messageType: 'company.CreatingCompanyOnPlatformRejected', topic: 'company' },
  { messageType: 'company.DeletingCompanyOnPlatformRejected', topic: 'company' },
  { messageType: 'company.CompanySignupDetailsSubmitted', topic: 'company' },
  { messageType: 'company.VehiclesCreationRejected', topic: 'company' },
  { messageType: 'company.VehiclesCreated', topic: 'company' },
  { messageType: 'company.UnsupportedTelematicsProviderDetailsSubmitted', topic: 'company' },
  { messageType: 'company.CompanyOnboardingStatusUpdated', topic: 'company' },
  { messageType: 'company.UpdatingCompanyOnboardingStatusRejected', topic: 'company' },
  { messageType: 'company.CompanyTagAdded', topic: 'company' },
  { messageType: 'company.CompanyTagRemoved', topic: 'company' },
  { messageType: 'company.AddingCompanyTagRejected', topic: 'company' },
  { messageType: 'company.RemovingCompanyTagRejected', topic: 'company' },
  { messageType: 'company.OneClickIntegrationDraftPromoted', topic: 'company' },
  { messageType: 'company.OneClickIntegrationDraftPromotionRejected', topic: 'company' },
  { messageType: 'company.CompanyTourFilterCreated', topic: 'company' },
  { messageType: 'company.CreatingCompanyTourFilterRejected', topic: 'company' },
  { messageType: 'company.CompanyTourFilterUpdated', topic: 'company' },
  { messageType: 'company.UpdatingCompanyTourFilterRejected', topic: 'company' },
  { messageType: 'company.CompanyTourFilterDeleted', topic: 'company' },
  { messageType: 'company.DeletingCompanyTourFilterRejected', topic: 'company' },
  { messageType: 'company.CompanyConfigurationUpdated', topic: 'company' },
  { messageType: 'company.UpdatingCompanyConfigurationRejected', topic: 'company' },
  { messageType: 'company.CompanyVehicleGroup', topic: 'company' },
  { messageType: 'company.CompanyVehicleGroupCreated', topic: 'company' },
  { messageType: 'company.CreatingCompanyVehicleGroupRejected', topic: 'company' },
  { messageType: 'company.CompanyVehicleGroupUpdated', topic: 'company' },
  { messageType: 'company.UpdatingCompanyVehicleGroupRejected', topic: 'company' },
  { messageType: 'company.CompanyVehicleGroupDeleted', topic: 'company' },
  { messageType: 'company.DeletingCompanyVehicleGroupRejected', topic: 'company' },
  { messageType: 'company.VehiclesAddedToCompanyVehicleGroup', topic: 'company' },
  { messageType: 'company.AddingVehiclesToCompanyVehicleGroupRejected', topic: 'company' },
  { messageType: 'company.VehiclesRemovedFromCompanyVehicleGroup', topic: 'company' },
  { messageType: 'company.RemovingVehiclesFromCompanyVehicleGroupRejected', topic: 'company' },
  { messageType: 'company.CompanyTransportNetworkFilterCreated', topic: 'company' },
  { messageType: 'company.CompanyTransportNetworkFilterUpdated', topic: 'company' },
  { messageType: 'company.CompanyTransportNetworkFilterDeleted', topic: 'company' },
  { messageType: 'company.CreatingCompanyTransportNetworkFilterRejected', topic: 'company' },
  { messageType: 'company.UpdatingCompanyTransportNetworkFilterRejected', topic: 'company' },
  { messageType: 'company.DeletingCompanyTransportNetworkFilterRejected', topic: 'company' },
  { messageType: 'company.CreatingOrUpdatingDataSharingConsentRejected', topic: 'company' },
  { messageType: 'company.RemoveVehicleTrackerRejected', topic: 'company' },
  { messageType: 'company.BulkInvitationSetCreated', topic: 'company' },
  { messageType: 'company.BulkInvitationSetUpdated', topic: 'company' },
  { messageType: 'company.BulkInvitationSetDeleted', topic: 'company' },
  { messageType: 'company.CreatingBulkInvitationSetRejected', topic: 'company' },
  { messageType: 'company.UpdatingBulkInvitationSetRejected', topic: 'company' },
  { messageType: 'company.DeletingBulkInvitationSetRejected', topic: 'company' },
  { messageType: 'company.SendingBulkInvitationRejected', topic: 'company' },
  { messageType: 'company.BulkInvitationSent', topic: 'company' },
  { messageType: 'company.CompanyIdentifiersActionRejected', topic: 'company' },
  { messageType: 'company.CompanyIdentifiersCreatedOrUpdated', topic: 'company' },
];

export interface MessageData {
  messageType: string;
  topic: string;
  keyFn: (entity: Tour | Company) => string;
}

export const getMessageOptions = (type: 'tour' | 'company') => {
  const defaultKeyFn =
    type === 'tour'
      ? (tour: Tour) => tour.tour_id.toString()
      : (company: Company) => `company:${company.company_id.toString()}`;
  const messageList = type === 'tour' ? tourMessageList : companyMessageList;
  return messageList
    .sort((a, b) => a.messageType.localeCompare(b.messageType))
    .map((message) => ({
      ...message,
      keyFn: ('keyFn' in message ? message.keyFn : defaultKeyFn) as (entity: Tour | Company) => string,
    }));
};
