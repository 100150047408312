import { Select } from '@sixfold/common-ui';
import { Loader } from '@sixfold/loader-container';
import { OptionalString, parseQueryString, updateQueryString } from '@sixfold/query-string';
import { notNil } from '@sixfold/typed-primitives';
import React, { useEffect } from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';

import { apiEndpointUsageLogs } from '../../company/graphql';
import {
  ApiEndpointUsageLog,
  ApiEndpointUsageLogsQuery,
  ApiEndpointUsageLogsQueryVariables,
  notEmpty,
} from '../../lib/graphql';
import {
  apiEndpointIdsMap,
  ApiEndpointUsageLogInList,
  ApiEndpointUsageLogsList,
  ImplementationStatus,
} from '../components/api_endpoint_usage_log_list';

export interface ListProps {
  data: {
    company_id: string;
    company_name: string | null;
    api_endpoint_usage_logs: ApiEndpointUsageLogInList[];
  }[];
}

class LoadingContainer extends Loader<ApiEndpointUsageLogsQuery, ListProps, ApiEndpointUsageLogsQueryVariables> {}
class ApiUsageContainerQuery extends Query<ApiEndpointUsageLogsQuery, ApiEndpointUsageLogsQueryVariables> {}

const isValidImplementationStatus = (
  status: string,
  implementationStatus: typeof ImplementationStatus,
): status is keyof typeof ImplementationStatus => {
  const statusArray: string[] = Object.keys(implementationStatus);
  return statusArray.includes(status);
};

const getStatusKey = (status: ImplementationStatus | ''): string | null => {
  if (status === '') return null;
  const statusKey = Object.keys(ImplementationStatus).find(
    (key) => ImplementationStatus[key as keyof typeof ImplementationStatus] === status,
  );
  return statusKey || null;
};

export const ApiUsageContainer = ({ location, history }: RouteComponentProps<object>) => {
  const [endpoint, setEndpoint] = React.useState('');
  const [status, setStatus] = React.useState<ImplementationStatus | ''>('');

  useEffect(() => {
    const query = parseQueryString({
      queryString: location.search,
      queryParametersToParse: {
        endpoint: OptionalString,
        status: OptionalString,
      },
    });
    const currentStatus =
      notNil(query.status) && isValidImplementationStatus(query.status, ImplementationStatus)
        ? ImplementationStatus[query.status]
        : '';
    const currentEndpoint = notNil(query.endpoint) && apiEndpointIdsMap.has(query.endpoint) ? query.endpoint : '';

    setEndpoint(currentEndpoint);
    setStatus(currentStatus);
  }, [location.search]);

  const onSetEndpoint = (value: string) => {
    const checkedValue = apiEndpointIdsMap.has(value) ? value : '';
    setEndpoint(checkedValue);
    history.replace(
      `${location.pathname}?${updateQueryString(location.search, {
        param: 'endpoint',
        value: value === '' ? undefined : value,
      })}`,
    );
  };

  const onSetStatus = (value: string, label: ImplementationStatus | '') => {
    const checkedValue = isValidImplementationStatus(value, ImplementationStatus) ? value : '';
    const checkedLabel = checkedValue !== '' && ImplementationStatus[checkedValue] === label ? label : '';
    setStatus(checkedLabel);
    history.replace(
      `${location.pathname}?${updateQueryString(location.search, {
        param: 'status',
        value: checkedValue === '' ? undefined : checkedValue,
      })}`,
    );
  };

  return (
    <React.Fragment>
      <ApiUsageContainerQuery
        query={apiEndpointUsageLogs}
        variables={{
          last: 500,
        }}>
        {(result) => (
          <LoadingContainer
            result={result}
            mapData={({ data }) => ({
              data:
                data.apiEndpointUsageLogs
                  ?.map((log: ApiEndpointUsageLog) => ({
                    company_id: log.companyId,
                    company_name: log.companyName,
                    api_endpoint_usage_logs: [
                      {
                        api_implemented_at: log.apiImplementedAt,
                        counters: log.counters,
                        endpoint_id: log.endpointId,
                        first_call_at: log.firstCallAt,
                        last_call_at: log.lastCallAt,
                        integration_identifier: log.integrationIdentifier,
                      },
                    ].filter((log) => !endpoint || log.endpoint_id === endpoint),
                  }))
                  .filter(notEmpty) ?? [],
            })}>
            {({ data }) => (
              <>
                <div style={{ marginTop: '30px' }}>
                  <h2 style={{ marginBottom: '20px' }}>Visibility API</h2>
                  <p>
                    This page lists the API usage in Transporeon Visibility. It includes Visibility API as well as other
                    APIs (Sixfold legacy API, OVD).
                  </p>
                  <div className="api-usage-container__filters">
                    <Select
                      className="api-usage-container__select"
                      label="Endpoint"
                      labelPosition="block"
                      onChange={(value) => onSetEndpoint(value?.value ?? '')}
                      options={Array.from(apiEndpointIdsMap.entries()).map((key) => ({
                        label: key[1],
                        value: key[0],
                      }))}
                      usePortal
                      isClearable
                      value={
                        endpoint !== '' && notNil(apiEndpointIdsMap.get(endpoint))
                          ? {
                              label: apiEndpointIdsMap.get(endpoint) as string,
                              value: endpoint,
                            }
                          : null
                      }
                    />
                    <Select
                      className="api-usage-container__select"
                      label="Implementation status"
                      labelPosition="block"
                      onChange={(value) => onSetStatus(value?.value ?? '', value?.label ?? '')}
                      options={Object.entries(ImplementationStatus).map(([key, label]) => ({
                        label,
                        value: key,
                      }))}
                      usePortal
                      isClearable
                      value={getStatusKey(status)}
                    />
                  </div>
                  <ApiEndpointUsageLogsList
                    data={data.map((log) => ({
                      company_id: log.company_id,
                      company_name: log.company_name,
                      api_endpoint_usage_logs_data: {
                        api_endpoint_usage_logs: log.api_endpoint_usage_logs,
                      },
                    }))}
                    isSingleCompany={false}
                    status={status}
                  />
                </div>
              </>
            )}
          </LoadingContainer>
        )}
      </ApiUsageContainerQuery>
    </React.Fragment>
  );
};
