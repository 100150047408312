import { Loader } from '@sixfold/loader-container';
import { BackofficeScope } from '@sixfold/session-interface';
import { isNil, notNil } from '@sixfold/typed-primitives';
import classnames from 'classnames';
import React from 'react';
import { Query } from 'react-apollo';
import { Link, NavLink, Route, Switch } from 'react-router-dom';
import { Button, Icon, List, Menu, Popup, Table } from 'semantic-ui-react';

import { ETALabel } from './eta_label';
import { ExternalEventList } from './external_event_list';
import { PlatformCallbackList } from './platform_callback_list';
import { useHighlightedStopIdContext } from './stop_highlight_context';
import { EventList } from './tour_event_list';
import { TourExternalEventLogList } from './tour_external_event_logs_list';
import { TourTripView } from './tour_trips';
import { TourVisibilityIssueList } from './tour_visibility_issue_list';
import { TourVisibilitySourceList } from './tour_visibility_source_list';
import { CompanyProfile } from '../../company/entities';
import { getTourKafkaQueryTimespan, KafkaContextProvider } from '../../components/bigquery/common/kafka_context';
import { TourKafkaTab } from '../../components/bigquery/tour_kafka/tour_kafka_tab';
import { TourTrafficTab } from '../../components/bigquery/tour_traffic/tour_traffic_tab';
import { ConfirmButton } from '../../components/confirm_button';
import { FormattedTimeSlot } from '../../components/date_formatting/date_formatted_timeslot';
import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { useTimeZone } from '../../components/date_formatting/timezone_context';
import { TagsInput } from '../../components/tags_input';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { getEmbedConfig } from '../../lib/data';
import {
  EtaType,
  ExternalEtaSource,
  TourVehicleAllocationSource,
  TourVehicleAllocationType,
  TripsDetailsQuery,
  TripsDetailsQueryVariables,
} from '../../lib/graphql';
import { capitalizeFirstLetter, indexValueToPercentage } from '../../lib/util/string';
import {
  classicMapViewByAddressUrl,
  classicMapViewByCoordinatesUrl,
  placeViewUrl,
  shipmentViewUrl,
  tourStopEtaHistoryUrl,
} from '../../lib/util/url';
import { Routes } from '../../routes';
import {
  CustomField,
  DeliveryTree as TourDeliveryTree,
  EmissionsCalculationError,
  ExternalEvent,
  Tour,
  TourCO2Emissions,
  TourDrivenDistance,
  TourFuelBasedEmissions,
  TourStop,
  TourStopEta,
  TourStopLocation,
  TourVehicleAllocation,
  TripsDetails,
  VisibilityService,
} from '../entities';
import { tripsDetailsQuery } from '../graphql';
import {
  convertFlatDeliveryTreeToTree,
  extractFieldValueFromCustomFields,
  getFormattedDelay,
  getFormattedGeocodingDirection,
  getFormattedGeocodingType,
  getFormattedLocation,
  getFormattedMatchLevel,
  getRowSpan,
  getStatusLabelColor,
  numberOfStatusProperties,
} from '../utils';
import { breakWordAtChar } from '../utils/break_word_util';

class RelatedTransportsTabQuery extends Query<TripsDetailsQuery, TripsDetailsQueryVariables> {}
class RelatedTransportsLoadingContainer extends Loader<
  TripsDetailsQuery,
  TripsDetails | null,
  TripsDetailsQueryVariables
> {}

export interface TourDetailsViewDataProps {
  data: {
    tour: Tour;
    availableTourTags?: { name: string }[];
  };
}

export interface TourDetailsViewProps {
  className?: string;

  onTourAbort(tourId: number): void;

  onTourRecalculate(tourId: number): void;

  onAddTourTag(options: { tourId: string; tagName: string }): void;

  onRemoveTourTag(options: { tourId: string; tagName: string }): void;
}

export const TourDetailsView: React.FC<TourDetailsViewDataProps & TourDetailsViewProps> = ({
  className,
  data: { tour },
  onTourAbort,
  onTourRecalculate,
  onAddTourTag,
  onRemoveTourTag,
}) => {
  const config = getEmbedConfig();
  if (config === null) {
    throw Error('Missing config');
  }
  return (
    <div className={classnames('tour__details', className)}>
      <TourHeaderButtons
        tour={tour}
        onAddTourTag={onAddTourTag}
        onTourAbort={onTourAbort}
        onTourRecalculate={onTourRecalculate}
        onRemoveTourTag={onRemoveTourTag}
      />
      <TourDetailsMenu tour={tour} />
    </div>
  );
};

/**
 *
 * Components for rendering parts of the tour details
 *
 */

const TourDetailsMenu: React.FunctionComponent<{ tour: Tour }> = ({ tour }) => {
  const isDeliveryEmpty = tour.deliveries.length === 0 && tour.deliveryTree.length === 0;
  const isVisibilityEmpty =
    tour.visibilitySegments.length === 0 &&
    tour.visibilitySources.length === 0 &&
    tour.visibilityIssues === null &&
    tour.trackingSegments.length === 0;
  const isEventsEmpty =
    tour.events.length === 0 &&
    tour.externalEvents.length === 0 &&
    tour.externalDeliveryEvents.length === 0 &&
    tour.externalStopEvents.length === 0;
  const isCarbonEmptyAndWithoutErrors = tour.plannedEmissions === null && tour.emissionsCalculationErrors?.length === 0;
  const isRelatedTransportsEmpty = !(
    tour.visibilityServices.includes(VisibilityService.VISIBILITY_OCEAN) ||
    tour.visibilityServices.includes(VisibilityService.VISIBILITY_AIR)
  );
  const hasNoTripData = isNil(tour.tripSegments) || tour.tripSegments.length === 0;

  const tourHasFullAllocationHistory = tour.vehicleAllocationHistory.length !== 0;

  const tourVehicleAllocations: TourVehicleAllocation[] = tourHasFullAllocationHistory
    ? tour.vehicleAllocationHistory.slice().reverse()
    : tour.vehicleAllocations;

  const [triggeredByAllocationId, setTriggeredByAllocationId] = React.useState<string | null>(null);

  const renderInfoTab = React.useCallback(
    () => (
      <>
        <GeneralTourInfo tour={tour} />
        <TourForwardings tour={tour} onTriggeredByClick={setTriggeredByAllocationId} />
        <TourVehicleAllocations
          allocations={tourVehicleAllocations}
          isFullHistory={tourHasFullAllocationHistory}
          activeAllocation={tour.activeVehicleAllocation}
          triggeredByAllocationId={triggeredByAllocationId}
        />
        <PlatformCallbackList tour={tour} />
        <TourExternalEventLogList tour={tour} />
      </>
    ),
    [tour, tourHasFullAllocationHistory, tourVehicleAllocations, triggeredByAllocationId],
  );

  const renderStopsTab = React.useCallback(() => <Stops tour={tour} />, [tour]);
  const renderKafkaTab = React.useCallback(() => <TourKafkaTab tour={tour} />, [tour]);
  const renderAPITrafficTab = React.useCallback(() => <TourTrafficTab tour={tour} />, [tour]);
  const renderRelatedTransportsTab = React.useCallback(() => <TourRelatedTransportsTab tour={tour} />, [tour]);

  const renderDeliveriesTab = React.useCallback(
    () => (
      <>
        <Deliveries tour={tour} />
        <TourDeliveryTrees tour={tour} />
      </>
    ),
    [tour],
  );

  const renderVisibilityTab = React.useCallback(
    () => (
      <>
        <VisibilityRanges tour={tour} />
        <TrackingRanges tour={tour} />
        <TourVisibilitySourceList sources={tour.visibilitySources} />
        <TourVisibilityIssueList
          visibilityIssues={tour.visibilityIssues}
          mainVisibilityIssue={tour.mainVisibilityIssue}
        />
      </>
    ),
    [tour],
  );

  const renderEventsTab = React.useCallback(
    () => (
      <>
        <EventList events={tour.events} />
        <ExternalEventList
          events={([] as ExternalEvent[]).concat(
            tour.externalEvents,
            tour.externalDeliveryEvents,
            tour.externalStopEvents,
          )}
        />
      </>
    ),
    [tour],
  );

  const renderUnifiedTab = React.useCallback(
    () => (
      <>
        <TourTripView segments={tour.tripSegments} locations={tour.tripLocations} deliveries={tour.tripDeliveryItems} />
      </>
    ),
    [tour],
  );

  const renderCarbonTab = React.useCallback(
    () => (
      <>
        <CalculatedEmissions type="planned" emissions={tour.plannedEmissions} />
        <FuelBasedEmissions emissions={tour.fuelBasedEmissions} />
        <DrivenDistance data={tour.drivenDistance} />
        <EmissionsCalculationErrors calculationErrors={tour.emissionsCalculationErrors}></EmissionsCalculationErrors>
      </>
    ),
    [tour],
  );

  const { initialFromDate, initialToDate } = getTourKafkaQueryTimespan(tour);
  return (
    <React.Fragment>
      <div className="ui blue pointing secondary menu tour__details__menu">
        <NavLink
          exact
          to={{
            pathname: Routes.Tour.generatePath({ tour_id: tour.tour_id.toString() }),
            search: window.location.search,
          }}
          className="item">
          Info
        </NavLink>
        <NavLink
          to={{
            pathname: Routes.TourStops.generatePath({ tour_id: tour.tour_id.toString() }),
            search: window.location.search,
          }}
          className="item">
          Stops
        </NavLink>
        {isDeliveryEmpty ? (
          <Menu.Item name="Deliveries" disabled={true} />
        ) : (
          <NavLink
            to={{
              pathname: Routes.TourDeliveries.generatePath({ tour_id: tour.tour_id.toString() }),
              search: window.location.search,
            }}
            className="item">
            Deliveries
          </NavLink>
        )}
        {isVisibilityEmpty ? (
          <Menu.Item name="Visibility" disabled={true} />
        ) : (
          <NavLink
            to={{
              pathname: Routes.TourVisibility.generatePath({ tour_id: tour.tour_id.toString() }),
              search: window.location.search,
            }}
            className="item">
            Visibility
          </NavLink>
        )}
        {isEventsEmpty ? (
          <Menu.Item name="Events" disabled={true} />
        ) : (
          <NavLink
            to={{
              pathname: Routes.TourEvents.generatePath({ tour_id: tour.tour_id.toString() }),
              search: window.location.search,
            }}
            className="item">
            Events
          </NavLink>
        )}
        {isCarbonEmptyAndWithoutErrors ? (
          <Menu.Item name="CO₂" disabled={true} />
        ) : (
          <NavLink
            to={{
              pathname: Routes.TourCarbon.generatePath({ tour_id: tour.tour_id.toString() }),
              search: window.location.search,
            }}
            className="item">
            CO₂
          </NavLink>
        )}
        {hasNoTripData ? (
          <Menu.Item name="Unified" disabled={true} />
        ) : (
          <NavLink
            to={{
              pathname: Routes.TourUnified.generatePath({ tour_id: tour.tour_id.toString() }),
              search: window.location.search,
            }}
            className="item">
            Unified
          </NavLink>
        )}
        {isRelatedTransportsEmpty ? null : (
          <NavLink
            to={{
              pathname: Routes.TourRelatedTransports.generatePath({ tour_id: tour.tour_id.toString() }),
              search: window.location.search,
            }}
            className="item">
            Related Transports
          </NavLink>
        )}
        <NavLink
          exact
          to={{
            pathname: Routes.TourKafka.generatePath({ tour_id: tour.tour_id.toString() }),
            search: window.location.search,
          }}
          className="item">
          Kafka
        </NavLink>
        <NavLink
          exact
          to={{
            pathname: Routes.TourTraffic.generatePath({ tour_id: tour.tour_id.toString() }),
            search: window.location.search,
          }}
          className="item">
          API Traffic
        </NavLink>
      </div>
      <div className="tour__details__content">
        <KafkaContextProvider initialFromDate={initialFromDate} initialToDate={initialToDate}>
          <Switch>
            <Route exact path={Routes.Tour.routerPath} render={renderInfoTab} />
            <Route path={Routes.TourStops.routerPath} render={renderStopsTab} />
            <Route path={Routes.TourDeliveries.routerPath} render={renderDeliveriesTab} />
            <Route path={Routes.TourVisibility.routerPath} render={renderVisibilityTab} />
            <Route path={Routes.TourEvents.routerPath} render={renderEventsTab} />
            <Route path={Routes.TourCarbon.routerPath} render={renderCarbonTab} />
            <Route path={Routes.TourUnified.routerPath} render={renderUnifiedTab} />
            <Route path={Routes.TourRelatedTransports.routerPath} render={renderRelatedTransportsTab} />
            <Route path={Routes.TourKafka.routerPath} render={renderKafkaTab} />
            <Route path={Routes.TourTraffic.routerPath} render={renderAPITrafficTab} />
          </Switch>
        </KafkaContextProvider>
      </div>
    </React.Fragment>
  );
};
function getMode(visibilityServices: VisibilityService[]) {
  if (visibilityServices.includes(VisibilityService.VISIBILITY_AIR)) {
    return 'air';
  } else if (visibilityServices.includes(VisibilityService.VISIBILITY_OCEAN)) {
    return 'ocean';
  } else {
    return 'road';
  }
}
const GeneralTourInfo: React.FunctionComponent<{ tour: Tour }> = ({ tour }) => {
  const mode = getMode(tour.visibilityServices);
  return (
    <Table structured basic compact="very" size="small">
      <Table.Body>
        <Table.Row verticalAlign="top">
          <Table.Cell width={3} rowSpan={17}>
            <b>General</b>
          </Table.Cell>
          <Table.Cell width={5}>Customer UI</Table.Cell>
          <Table.Cell>
            {tour.shipper !== null ? (
              <a
                target="_blank"
                title="Shipper"
                href={shipmentViewUrl(tour.shipper?.company_id ?? null, tour.tour_id.toString(), mode)}
                rel="noreferrer">
                📦
              </a>
            ) : (
              <span>No shipper to link to</span>
            )}
            &nbsp;
            {tour.carrier !== null ? (
              <a
                target="_blank"
                title="Carrier"
                href={shipmentViewUrl(tour.carrier?.company_id ?? null, tour.tour_id.toString(), mode)}
                rel="noreferrer">
                🚛
              </a>
            ) : (
              <span>No carrier to link to</span>
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Transport ID</Table.Cell>
          <Table.Cell>{tour.transport_id}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Shipper transport number</Table.Cell>
          <Table.Cell>{tour.shipper_transport_number ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Site</Table.Cell>
          <Table.Cell>
            {tour.site !== null ? (
              <>
                {tour.site.name} ({tour.site.site_id})
              </>
            ) : (
              '-'
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>User groups</Table.Cell>
          <Table.Cell>
            {tour.userGroupsOnPlatform.map((userGroupOnPlatform, idx) => (
              <>
                <Link
                  style={{ wordBreak: 'break-word' }}
                  to={Routes.CompanyUserGroup.generatePath({
                    company_id: userGroupOnPlatform.company?.company_id ?? tour.shipper?.company_id ?? 'NA',
                    group_id: userGroupOnPlatform.groupId,
                  })}>
                  {userGroupOnPlatform.name}
                </Link>
                {idx < tour.userGroupsOnPlatform.length - 1 && <>, </>}
              </>
            ))}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Visibility index</Table.Cell>
          <Table.Cell>
            <div style={visibilityIndexStyle(tour.visibilityIndex)}>{indexValueToPercentage(tour.visibilityIndex)}</div>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Visibility services</Table.Cell>
          <Table.Cell>{tour.visibilityServices.length > 0 ? tour.visibilityServices.join(', ') : '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Bill of lading number</Table.Cell>
          <Table.Cell>{extractFieldValueFromCustomFields(tour, 'billofladingNumber')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Equipment number</Table.Cell>
          <Table.Cell>{extractFieldValueFromCustomFields(tour, 'equipmentNumber')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Booking number</Table.Cell>
          <Table.Cell>{extractFieldValueFromCustomFields(tour, 'bookingNumber')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>SCAC</Table.Cell>
          <Table.Cell>{extractFieldValueFromCustomFields(tour, 'scac')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Tour type</Table.Cell>
          <Table.Cell>{tour.tour_type ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Parent tour id</Table.Cell>
          <Table.Cell>
            {tour.parent_tour_id !== null ? (
              <Link to={Routes.Tour.generatePath({ tour_id: tour.parent_tour_id })}>{tour.parent_tour_id}</Link>
            ) : (
              '-'
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Volume</Table.Cell>
          <Table.Cell>
            {tour.volume?.value ?? '-'} {tour.volume?.unit}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>External carrier</Table.Cell>
          <Table.Cell>
            Name: {tour.externalCarrier?.companyName ?? '-'} (ID: {tour.externalCarrier?.companyID ?? '-'})
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Load type</Table.Cell>
          <Table.Cell>{tour.loadType ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Shipment load type</Table.Cell>
          <Table.Cell>{tour.shipmentType ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Incoterms</Table.Cell>
          <Table.Cell>{tour.incoterm ? `${tour.incoterm.term ?? '-'} / ${tour.incoterm.city ?? '-'}` : '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Main mode</Table.Cell>
          <Table.Cell>{tour.mainMode ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row verticalAlign="top">
          <Table.Cell rowSpan={3}>
            <b>Participants</b>
          </Table.Cell>
          <Table.Cell>Carrier</Table.Cell>
          <Table.Cell>
            {tour.carrier !== null ? (
              <Link to={Routes.Company.generatePath({ company_id: tour.carrier.company_id })}>
                {tour.carrier.company_name}
              </Link>
            ) : (
              '-'
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Shipper</Table.Cell>
          <Table.Cell>
            {tour.shipper !== null ? (
              <Link to={Routes.Company.generatePath({ company_id: tour.shipper.company_id })}>
                {tour.shipper.company_name}
              </Link>
            ) : (
              '-'
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Created by company</Table.Cell>
          <Table.Cell>
            {tour.createdBy !== null ? (
              <Link to={Routes.Company.generatePath({ company_id: tour.createdBy.company_id })}>
                {tour.createdBy.company_name}
              </Link>
            ) : (
              '-'
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row verticalAlign="top">
          <Table.Cell rowSpan={getRowSpan(tour, numberOfStatusProperties(tour), null, 'abort_time', 'aborted_by_user')}>
            <b>Status calculation</b>
          </Table.Cell>
          <Table.Cell>Status</Table.Cell>
          <Table.Cell>
            <div className={classnames('ui label', getStatusLabelColor(tour.status))}>{tour.status ?? '-'}</div>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Vehicle</Table.Cell>
          <Table.Cell>
            <VehicleInfo vehicle={tour.currentVehicle} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Directions provider</Table.Cell>
          <Table.Cell>{tour.route?.provider ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Transport mode</Table.Cell>
          <Table.Cell>{tour.transportMode ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Tour total distance</Table.Cell>
          <Table.Cell>
            {tour.tourDistance?.totalDistanceInMeters !== undefined
              ? `${tour.tourDistance.totalDistanceInMeters}m`
              : '-'}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Created at</Table.Cell>
          <Table.Cell>
            <FormattedDate date={tour.created_at} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Start time</Table.Cell>
          <Table.Cell>
            <FormattedDate date={tour.start_time} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Complete time</Table.Cell>
          <Table.Cell>
            <FormattedDate date={tour.complete_time} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Visibility start time</Table.Cell>
          <Table.Cell>
            <FormattedDate date={tour.visibility_start_time} />
          </Table.Cell>
        </Table.Row>
        {tour.activeVehicleAllocation !== null &&
          tour.activeVehicleAllocation.allocationType === TourVehicleAllocationType.TOUR_ALLOCATION &&
          tour.activeVehicleAllocation.startTrackingAt !== null && (
            <Table.Row>
              <Table.Cell>External tracking start</Table.Cell>
              <Table.Cell>
                <FormattedDate date={tour.activeVehicleAllocation.startTrackingAt} />
              </Table.Cell>
            </Table.Row>
          )}
        <Table.Row>
          <Table.Cell>Visibility end time</Table.Cell>
          <Table.Cell>
            <FormattedDate date={tour.visibility_end_time} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Tracking start time</Table.Cell>
          <Table.Cell>
            <FormattedDate date={tour.trackingStartTime} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Tracking end time</Table.Cell>
          <Table.Cell>
            <FormattedDate date={tour.trackingEndTime} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Abandon time</Table.Cell>
          <Table.Cell>
            <FormattedDate date={tour.metadata?.abandon_time} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Warnings</Table.Cell>
          <Table.Cell>
            {tour.warnings.length > 0
              ? tour.warnings.map(({ type, metadata }, index) => {
                  return (
                    <>
                      {index !== 0 ? ', ' : ''}
                      {type}
                      {metadata && Object.keys(metadata).length > 0 ? (
                        <Popup wide hoverable trigger={<i className="info circle icon" />} on="hover">
                          <div>{JSON.stringify(metadata, null, 2)}</div>
                        </Popup>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })
              : '-'}
          </Table.Cell>
        </Table.Row>
        {tour.isTrackingDisabled === true && (
          <Table.Row>
            <Table.Cell>Tracking</Table.Cell>
            <Table.Cell>
              <div className={classnames('ui label', 'red')}>{'disabled'}</div>
            </Table.Cell>
          </Table.Row>
        )}
        <Table.Row>
          <Table.Cell>Failure reason</Table.Cell>
          <Table.Cell>{tour.failureReason?.type ?? '-'}</Table.Cell>
        </Table.Row>
        {tour.abort_time !== null && (
          <Table.Row>
            <Table.Cell>Abort time</Table.Cell>
            <Table.Cell>
              <FormattedDate date={tour.abort_time} />
            </Table.Cell>
          </Table.Row>
        )}
        {tour.aborted_by_user !== null && (
          <Table.Row>
            <Table.Cell>Aborted by</Table.Cell>
            <Table.Cell>
              <Link to={Routes.User.generatePath({ user_id: tour.aborted_by_user.user_id })}>
                {tour.aborted_by_user.first_name} {tour.aborted_by_user.last_name}
              </Link>
            </Table.Cell>
          </Table.Row>
        )}
        <Table.Row verticalAlign="top">
          <Table.Cell rowSpan={5}>
            <b>Platform</b>
          </Table.Cell>
          <Table.Cell>Name</Table.Cell>
          <Table.Cell>{tour.platform?.platform_name ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Platform branch ID</Table.Cell>
          <Table.Cell>{tour.platformBranchId ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Platform transport number</Table.Cell>
          <Table.Cell>{tour.platform_transport_number ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Platform carrier ID</Table.Cell>
          <Table.Cell>{tour.platform_carrier_id ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Platform shipper ID</Table.Cell>
          <Table.Cell>{tour.platform_shipper_id ?? '-'}</Table.Cell>
        </Table.Row>
        {tour.customFields.length > 0 && (
          <>
            <Table.Row verticalAlign="top">
              <Table.Cell rowSpan={5}>
                <b>Custom fields</b>
              </Table.Cell>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>Value(s)</Table.Cell>
            </Table.Row>
            <CustomFieldsTableItems customFields={tour.customFields} />
          </>
        )}
      </Table.Body>
    </Table>
  );
};

const Stops: React.FunctionComponent<{ tour: Pick<Tour, 'working_stop_id' | 'stops' | 'tour_id' | 'createdBy'> }> = ({
  tour: { working_stop_id, stops, tour_id, createdBy },
}) => {
  const [collapsedStopIds, setCollapsedStopIds] = React.useState(new Set());
  const [allStopsCollapsed, setAllStopsCollapsed] = React.useState(false);
  const { setHighlightedStopId } = useHighlightedStopIdContext();
  React.useEffect(() => {
    const stopIds = stops.map(({ stop_id }) => stop_id);
    setAllStopsCollapsed(stopIds.every((stopId) => collapsedStopIds.has(stopId)));
  }, [collapsedStopIds, stops]);

  const toggleAllStopCollapsing = React.useCallback(() => {
    if (allStopsCollapsed) {
      setCollapsedStopIds(new Set());
    } else {
      setCollapsedStopIds(new Set(stops.map(({ stop_id }) => stop_id)));
    }
  }, [allStopsCollapsed, setCollapsedStopIds, stops]);

  const toggleStopCollapsing = React.useCallback(
    (stopId: number) => {
      const newSet = new Set(collapsedStopIds);

      if (collapsedStopIds.has(stopId)) {
        newSet.delete(stopId);
      } else {
        newSet.add(stopId);
      }

      setCollapsedStopIds(newSet);
    },
    [setCollapsedStopIds, collapsedStopIds],
  );

  const { timeZone } = useTimeZone();

  return (
    <div className="ui list">
      <h3>
        {`Stops (${stops.length})`}
        <Button
          floated="right"
          compact
          basic
          onClick={toggleAllStopCollapsing}
          icon={allStopsCollapsed ? 'angle down' : 'angle up'}
          size="tiny"
        />
      </h3>
      {stops.map((stop) => (
        <div
          className="item tour__tour-details__stop-item"
          key={stop.stop_id}
          onMouseEnter={() => setHighlightedStopId(stop.stop_id)}
          onMouseLeave={() => setHighlightedStopId(undefined)}>
          <Table structured compact size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  <div className="tour__tour-details__stop-item__stop-header">
                    <div className="tour__tour-details__stop-item__stop-header__address">
                      <i
                        className={classnames({
                          'map pin icon': true,
                          stop_loading: stop.type === 'loading',
                          stop_unloading: stop.type === 'unloading',
                        })}
                      />
                      {getFormattedLocation(stop.location)}
                    </div>
                    {stop.status !== null && (stop.status === 'at-stop' || stop.status === 'departed') ? (
                      <div className="ui tiny green label">{capitalizeFirstLetter(stop.status)}</div>
                    ) : (
                      <ETALabel
                        eta={
                          stop.estimated_arrival !== null
                            ? timeZone === 'UTC'
                              ? stop.estimated_arrival
                              : new Date(stop.estimated_arrival)
                            : null
                        }
                        status={stop.estimated_arrival_status ?? null}
                      />
                    )}
                    {stop.stop_id === working_stop_id ? <div className="ui tiny label">Working stop</div> : null}
                    <Button
                      size="tiny"
                      compact
                      basic
                      onClick={() => toggleStopCollapsing(stop.stop_id)}
                      icon={collapsedStopIds.has(stop.stop_id) ? 'angle down' : 'angle up'}
                    />
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {!collapsedStopIds.has(stop.stop_id) && (
              <Table.Body>
                <Table.Row verticalAlign="top">
                  <Table.Cell width={3} rowSpan={getRowSpan(stop, 3, null, 'type')}>
                    <b>General</b>
                  </Table.Cell>
                  <Table.Cell width={5}>Stop ID</Table.Cell>
                  <Table.Cell>{stop.stop_id ?? '-'}</Table.Cell>
                </Table.Row>
                {stop.type != null && (
                  <Table.Row>
                    <Table.Cell>Type</Table.Cell>
                    <Table.Cell>{stop.type}</Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>Status</Table.Cell>
                  <Table.Cell>{stop.status ?? '-'}</Table.Cell>
                </Table.Row>

                <Table.Row verticalAlign="top">
                  <Table.Cell rowSpan={getRowSpan(stop, 3, null, 'booking_id')}>
                    <b>IDs</b>
                  </Table.Cell>
                  <Table.Cell>Reference</Table.Cell>
                  <Table.Cell>{stop.stop_reference_id ?? '-'}</Table.Cell>
                </Table.Row>
                {stop.booking_id != null && (
                  <Table.Row>
                    <Table.Cell>Booking</Table.Cell>
                    <Table.Cell>{stop.booking_id ?? '-'}</Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>Deliveries</Table.Cell>
                  <Table.Cell>
                    {stop.delivery_ids !== null && stop.delivery_ids.length > 0 ? stop.delivery_ids.join(', ') : '-'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row verticalAlign="top">
                  <Table.Cell
                    rowSpan={
                      (stop.actual_sequence_number !== stop.sequence_number ? 1 : 0) +
                      (stop.sequence_number !== stop.platformSequenceNumber ? 1 : 0) +
                      1
                    }>
                    <b>Sequence number</b>
                  </Table.Cell>
                  <Table.Cell>
                    <Popup wide hoverable on="hover" trigger={<div className="hoverable">Platform</div>}>
                      Stop sequence sent from platform
                    </Popup>
                  </Table.Cell>
                  <Table.Cell>{stop.platformSequenceNumber ?? '-'}</Table.Cell>
                </Table.Row>
                {stop.actual_sequence_number !== stop.sequence_number && (
                  <Table.Row>
                    <Table.Cell>
                      <Popup wide hoverable on="hover" trigger={<div className="hoverable">Actual</div>}>
                        Real stop visit sequence for truck
                      </Popup>
                    </Table.Cell>
                    <Table.Cell>{stop.actual_sequence_number ?? '-'}</Table.Cell>
                  </Table.Row>
                )}
                {stop.sequence_number !== stop.platformSequenceNumber && (
                  <Table.Row>
                    <Table.Cell>
                      <Popup wide hoverable on="hover" trigger={<div className="hoverable">Calculated</div>}>
                        Sequence calculated using platform and delivery sequence
                      </Popup>
                    </Table.Cell>
                    <Table.Cell>{stop.sequence_number}</Table.Cell>
                  </Table.Row>
                )}

                <Table.Row verticalAlign="top">
                  <Table.Cell
                    rowSpan={getRowSpan(
                      stop.location,
                      3,
                      (obj: TourStopLocation, key: keyof TourStopLocation) => {
                        return key === 'address' && obj.address?.full_address == null;
                      },
                      'address',
                    )}>
                    <b>Location</b>
                  </Table.Cell>
                  <Table.Cell>Map links</Table.Cell>
                  <Table.Cell>
                    <a
                      className="link"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Map view"
                      href={classicMapViewByAddressUrl({
                        address: getFormattedLocation(stop.location),
                      })}>
                      🗺️
                    </a>{' '}
                    <a
                      className="link"
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Satellite view"
                      href={classicMapViewByCoordinatesUrl({
                        lat: stop.location?.position?.lat ?? null,
                        lng: stop.location?.position?.lng ?? null,
                      })}>
                      🛰
                    </a>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    Address
                    <Popup
                      size="mini"
                      hoverable
                      trigger={
                        <span>
                          <Icon name="info circle" />
                        </span>
                      }>
                      The displayed address might not be the one provided by the transport creator.
                    </Popup>
                  </Table.Cell>
                  <Table.Cell>{stop.location?.address?.full_address ?? '-'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Place reference ID</Table.Cell>
                  <Table.Cell>{stop.place_reference_id ?? '-'}</Table.Cell>
                </Table.Row>
                <LocationDetails stop={stop} tourCreatedBy={createdBy} />
                <Table.Row verticalAlign="top">
                  <Table.Cell rowSpan={notNil(stop.vehicleAllocation?.startTrackingAt) ? 2 : 1}>
                    <b>Allocation</b>
                  </Table.Cell>
                  <Table.Cell>Vehicle</Table.Cell>
                  <Table.Cell>
                    <VehicleInfo vehicle={stop.vehicleAllocation} />
                  </Table.Cell>
                </Table.Row>
                {notNil(stop.vehicleAllocation?.startTrackingAt) && (
                  <Table.Row>
                    <Table.Cell>External tracking start</Table.Cell>
                    <Table.Cell>
                      <FormattedDate date={stop.vehicleAllocation?.startTrackingAt} />
                    </Table.Cell>
                  </Table.Row>
                )}

                <Table.Row verticalAlign="top">
                  <Table.Cell
                    rowSpan={getRowSpan(
                      stop,
                      7,
                      null,
                      'expected_duration',
                      'deadline',
                      'arrival_time',
                      'arrival_delay_in_seconds',
                      'departure_time',
                    )}>
                    <b>Timeslot / Timing</b>
                  </Table.Cell>
                  <Table.Cell>Timeslot</Table.Cell>
                  <Table.Cell>
                    {stop.timeslot !== null ? (
                      <FormattedTimeSlot startDate={stop.timeslot.begin} endDate={stop.timeslot.end} />
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Timeslot in stop timezone</Table.Cell>
                  <Table.Cell>
                    {stop.timeslot !== null && stop.timeslot.timezone !== null ? (
                      <FormattedTimeSlot
                        startDate={stop.timeslot.begin}
                        endDate={stop.timeslot.end}
                        timezone={stop.timeslot.timezone}
                      />
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                </Table.Row>
                {stop.expected_duration != null && (
                  <Table.Row>
                    <Table.Cell>Expected duration</Table.Cell>
                    <Table.Cell>{stop.expected_duration}</Table.Cell>
                  </Table.Row>
                )}
                {stop.deadline !== null && (
                  <Table.Row>
                    <Table.Cell>Deadline</Table.Cell>
                    <Table.Cell>
                      <FormattedDate date={stop.deadline} />
                    </Table.Cell>
                  </Table.Row>
                )}
                {stop.arrival_time !== null && (
                  <Table.Row>
                    <Table.Cell>Arrival time</Table.Cell>
                    <Table.Cell>
                      <FormattedDate date={stop.arrival_time} />
                    </Table.Cell>
                  </Table.Row>
                )}
                {stop.arrival_delay_in_seconds !== null && (
                  <Table.Row>
                    <Table.Cell>Arrival delay</Table.Cell>
                    <Table.Cell>{getFormattedDelay(stop.arrival_delay_in_seconds)}</Table.Cell>
                  </Table.Row>
                )}
                {stop.departure_time !== null && (
                  <Table.Row>
                    <Table.Cell>Departure time</Table.Cell>
                    <Table.Cell>
                      <FormattedDate date={stop.departure_time} />
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            )}
          </Table>
          {!collapsedStopIds.has(stop.stop_id) && <StopETADetails stop={stop} tourId={tour_id} />}
        </div>
      ))}
    </div>
  );
};

const TourRelatedTransportsTab: React.FunctionComponent<{ tour: Tour }> = ({ tour }) => {
  return (
    <RelatedTransportsTabQuery query={tripsDetailsQuery} variables={{ tour_id: tour.tour_id }} fetchPolicy="no-cache">
      {(result) => (
        <RelatedTransportsLoadingContainer
          result={result}
          mapData={({ data }) => {
            const { tour } = data;
            return {
              tour_id: tour?.tour_id,
              relatedTransports: tour?.relatedTransports,
            } as TripsDetails;
          }}>
          {(tripTour) => (
            <div>
              <h3>Related Transports</h3>
              <Table compact="very" size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Tour ID</Table.HeaderCell>
                    <Table.HeaderCell>Transport Number</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {tripTour?.relatedTransports.length === 0 ? (
                    <Table.Row textAlign="center">
                      <Table.Cell colSpan="3">No related transports</Table.Cell>
                    </Table.Row>
                  ) : (
                    tripTour?.relatedTransports.map((relatedTransport) => (
                      <Table.Row key={relatedTransport.tour_id}>
                        <Table.Cell>
                          <Link to={Routes.Tour.generatePath({ tour_id: relatedTransport.tour_id.toString() })}>
                            Tour {relatedTransport.tour_id}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>{relatedTransport.shipper_transport_number ?? '-'}</Table.Cell>
                        <Table.Cell>
                          {relatedTransport.status && (
                            <div className={classnames('ui label', getStatusLabelColor(relatedTransport.status))}>
                              {relatedTransport.status}
                            </div>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ))
                  )}
                </Table.Body>
              </Table>
            </div>
          )}
        </RelatedTransportsLoadingContainer>
      )}
    </RelatedTransportsTabQuery>
  );
};

const LocationDetails: React.FunctionComponent<{
  stop: TourStop;
  tourCreatedBy: CompanyProfile | null;
}> = ({ stop, tourCreatedBy }) => {
  const [showAddLocInfo, setShowAddLocInfo] = React.useState<boolean>(false);
  return (
    <React.Fragment>
      {showAddLocInfo && (
        <React.Fragment>
          <Table.Row verticalAlign="top">
            <Table.Cell width={3} rowSpan="15">
              <b>{'Additional location info'}</b>
            </Table.Cell>
            <Table.Cell rowSpan="6">Customer-provided address</Table.Cell>
            <Table.Cell>country: {stop.location?.customerProvidedAddress?.country ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>city: {stop.location?.customerProvidedAddress?.city ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>zipcode: {stop.location?.customerProvidedAddress?.zipcode ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>street_address: {stop.location?.customerProvidedAddress?.street_address ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>state: {stop.location?.customerProvidedAddress?.state ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>company_name: {stop.location?.name ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={5}>Coordinate provider</Table.Cell>
            <Table.Cell>{stop.location?.position?.provider ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={5}>Platform geocoding type</Table.Cell>
            <Table.Cell>{getFormattedGeocodingType(stop.location)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Coordinate match level</Table.Cell>
            <Table.Cell>{getFormattedMatchLevel(stop.location)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Geocoding direction</Table.Cell>
            <Table.Cell>{getFormattedGeocodingDirection(stop.location?.position?.geocodingDirection)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Location Reference ID</Table.Cell>
            <Table.Cell>{stop.location?.location_reference_id ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Booking location name</Table.Cell>
            <Table.Cell>{stop.location?.bookingLocationName ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Gate</Table.Cell>
            <Table.Cell>
              {stop.location?.gate ?? '-'} (ID: {stop.location?.gate_id ?? '-'})
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={3} rowSpan="2">
              Place
            </Table.Cell>
            <Table.Cell>
              {notNil(stop.location?.place) ? (
                <a
                  className="link"
                  rel="noreferrer"
                  target="_blank"
                  title="Place details"
                  href={placeViewUrl(tourCreatedBy, stop.location?.place?.place_id)}>
                  {stop.location?.place?.place_id} {stop.location?.place?.name ? `(${stop.location?.place?.name})` : ''}{' '}
                  {stop.location?.place?.isSixfoldGenerated ? '(Sixfold generated)' : ''}
                </a>
              ) : (
                '-'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {notNil(stop.location?.place) ? (
                notNil(stop.place_reference_id) ? (
                  <>Place matched by reference_id: {stop.place_reference_id}</>
                ) : (
                  <>Place matched by customer-provided address</>
                )
              ) : (
                '-'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={3}>Short area code</Table.Cell>
            <Table.Cell colSpan="2">{stop.location?.address?.shortAreaCode ?? '-'}</Table.Cell>
          </Table.Row>
        </React.Fragment>
      )}
      <Table.Row>
        <Table.Cell colSpan="3" textAlign="center">
          <Button size="mini" onClick={() => setShowAddLocInfo(!showAddLocInfo)}>
            {showAddLocInfo ? 'Hide additional location info' : 'Show additional location info'}
          </Button>
        </Table.Cell>
      </Table.Row>
    </React.Fragment>
  );
};

const TourVehicleAllocations: React.FunctionComponent<{
  allocations: TourVehicleAllocation[];
  isFullHistory: boolean;
  activeAllocation: TourVehicleAllocation;
  triggeredByAllocationId: string | null;
}> = ({ allocations, isFullHistory, activeAllocation, triggeredByAllocationId }) => (
  <>
    <List horizontal>
      <List.Item className={'tour__feature__flag'}>
        <h3>Vehicle {isFullHistory ? 'allocation history' : 'allocations'}</h3>
      </List.Item>
      {!isFullHistory && (
        <>
          <List.Item>
            <Icon bordered inverted color={'yellow'} name={'exclamation'} />
          </List.Item>
          <List.Item className={'tour__feature__flag'}>
            No allocation history, showing final state (per source)!
          </List.Item>
        </>
      )}
    </List>
    <Table compact="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Source</Table.HeaderCell>
          <Table.HeaderCell>Allocation</Table.HeaderCell>
          <Table.HeaderCell>Switch Type</Table.HeaderCell>
          <Table.HeaderCell>Start Tracking at</Table.HeaderCell>
          <Table.HeaderCell>Allocated at</Table.HeaderCell>
          <Table.HeaderCell>Allocated by</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {allocations.length === 0 ? (
          <Table.Row textAlign="center">
            <Table.Cell colSpan="6">No vehicle allocations</Table.Cell>
          </Table.Row>
        ) : (
          allocations.map((allocation, idx) => {
            const isActiveAllocation = isFullHistory && allocation.allocationId === activeAllocation.allocationId;
            const isAllocationClear = allocation.clearAllocation === true;
            const triggeredForwarding = triggeredByAllocationId === allocation.allocationId;
            const allocationSource = breakWordAtChar(allocation.source, '_');
            const remoteAllocationSource = breakWordAtChar(allocation.remoteAllocationSource, '_');

            return (
              <Table.Row
                key={idx}
                warning={isAllocationClear}
                className={classnames({
                  positive: triggeredForwarding,
                  active__allocation: isActiveAllocation,
                })}>
                <Table.Cell>{allocationSource}</Table.Cell>
                <Table.Cell>
                  <AllocationDetails allocation={allocation} />
                </Table.Cell>
                <Table.Cell>{allocation.allocationSwitchType ?? '-'}</Table.Cell>
                <Table.Cell>
                  <FormattedDate date={allocation.startTrackingAt} />
                </Table.Cell>
                <Table.Cell>
                  <FormattedDate date={allocation.allocatedAt} />
                </Table.Cell>
                <Table.Cell>
                  {notNil(allocation.allocatedByCompany) ? (
                    <Link
                      style={{ wordBreak: 'break-word' }}
                      to={Routes.Company.generatePath({ company_id: allocation.allocatedByCompany.company_id })}>
                      {allocation.allocatedByCompany.company_name !== null
                        ? allocation.allocatedByCompany.company_name
                        : 'No company name'}
                    </Link>
                  ) : (
                    '-'
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Popup wide hoverable trigger={<i className="info circle icon" />} on="hover">
                    <div>Platform Source: {remoteAllocationSource}</div>
                    <div>Device Type: {allocation.allocationDeviceType ?? '-'}</div>
                    <div>
                      Vehicle Matching Disabled:{' '}
                      {isNil(allocation.disableVehicleMatching) ? '-' : allocation.disableVehicleMatching.toString()}
                    </div>
                  </Popup>
                </Table.Cell>
              </Table.Row>
            );
          })
        )}
      </Table.Body>
    </Table>
  </>
);

const TourForwardings: React.FunctionComponent<{
  tour: Pick<Tour, 'forwardingChain'>;
  onTriggeredByClick: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({ tour: { forwardingChain }, onTriggeredByClick }) => (
  <>
    <h3>Tour forwarding</h3>
    <Table compact="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>From</Table.HeaderCell>
          <Table.HeaderCell>To</Table.HeaderCell>
          <Table.HeaderCell>Forwarded at</Table.HeaderCell>
          <Table.HeaderCell>Forwarded by</Table.HeaderCell>
          <Table.HeaderCell>Triggered by</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {forwardingChain.length === 0 ? (
          <Table.Row textAlign="center">
            <Table.Cell colSpan="4">No forwardings</Table.Cell>
          </Table.Row>
        ) : (
          forwardingChain.map((forwarding, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>
                {forwarding.from !== null ? (
                  <Link to={Routes.Company.generatePath({ company_id: forwarding.from.company_id })}>
                    {forwarding.from.company_name}
                  </Link>
                ) : (
                  'Unspecified'
                )}
              </Table.Cell>
              <Table.Cell>
                <Link to={Routes.Company.generatePath({ company_id: forwarding.to.company_id })}>
                  {forwarding.to.company_name}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <FormattedDate date={forwarding.forwardedAt} />
              </Table.Cell>
              <Table.Cell>
                {forwarding.forwardedBy !== null ? (
                  <Link to={Routes.User.generatePath({ user_id: forwarding.forwardedBy.user_id })}>
                    {forwarding.forwardedBy.first_name} {forwarding.forwardedBy.last_name}
                  </Link>
                ) : (
                  '-'
                )}
              </Table.Cell>
              <Table.Cell>
                <span className="span-link" onClick={() => onTriggeredByClick(forwarding.triggeredByAllocationId)}>
                  {forwarding.triggeredByAllocationId}
                </span>
              </Table.Cell>
            </Table.Row>
          ))
        )}
      </Table.Body>
    </Table>
  </>
);

const VisibilityRanges: React.FunctionComponent<{
  tour: Pick<Tour, 'visibilitySegments' | 'vehicles'>;
}> = ({ tour: { visibilitySegments, vehicles } }) => (
  <>
    <h3>Visibility ranges</h3>
    <Table compact="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Vehicle</Table.HeaderCell>
          <Table.HeaderCell>From</Table.HeaderCell>
          <Table.HeaderCell>To</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {visibilitySegments.length === 0 ? (
          <Table.Row textAlign="center">
            <Table.Cell colSpan="3">No visibility ranges</Table.Cell>
          </Table.Row>
        ) : (
          visibilitySegments.map((segment, idx) => {
            const vehicle = vehicles.find((vehicle) => vehicle.vehicle_id === segment.vehicleId);

            return (
              <Table.Row key={idx}>
                <Table.Cell>
                  {vehicle !== undefined ? (
                    <Link to={Routes.Vehicle.generatePath({ vehicle_id: vehicle.vehicle_id })}>
                      {vehicle.license_plate_number ?? <i>#{vehicle.vehicle_id}</i>}
                    </Link>
                  ) : (
                    <i>#{segment.vehicleId}</i>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <FormattedDate date={segment.visibilityStartDate} />
                </Table.Cell>
                <Table.Cell>
                  <FormattedDate date={segment.visibilityEndDate} />
                </Table.Cell>
              </Table.Row>
            );
          })
        )}
      </Table.Body>
    </Table>
  </>
);

const TrackingRanges: React.FunctionComponent<{
  tour: Pick<Tour, 'trackingSegments' | 'vehicles'>;
}> = ({ tour: { trackingSegments, vehicles } }) => (
  <>
    <h3>Tracking ranges</h3>
    <Table compact="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Vehicle</Table.HeaderCell>
          <Table.HeaderCell>From</Table.HeaderCell>
          <Table.HeaderCell>To</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {trackingSegments.length === 0 ? (
          <Table.Row textAlign="center">
            <Table.Cell colSpan="3">No tracking ranges</Table.Cell>
          </Table.Row>
        ) : (
          trackingSegments.map((segment, idx) => {
            const vehicle = vehicles.find((vehicle) => vehicle.vehicle_id === segment.vehicleId);

            return (
              <Table.Row key={idx}>
                <Table.Cell>
                  {vehicle !== undefined ? (
                    <Link to={Routes.Vehicle.generatePath({ vehicle_id: vehicle.vehicle_id })}>
                      {vehicle.license_plate_number ?? <i>#{vehicle.vehicle_id}</i>}
                    </Link>
                  ) : (
                    <i>#{segment.vehicleId}</i>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <FormattedDate date={segment.trackingStartDate} />
                </Table.Cell>
                <Table.Cell>
                  <FormattedDate date={segment.trackingEndDate} />
                </Table.Cell>
              </Table.Row>
            );
          })
        )}
      </Table.Body>
    </Table>
  </>
);

const CalculatedEmissions: React.FunctionComponent<{
  emissions: TourCO2Emissions | null;
  type: string;
}> = ({ type, emissions }) => {
  const [allCollapsed, setAllCollapsed] = React.useState(false);
  const [collapsedLegIndexes, setCollapsedLegIndexes] = React.useState(new Set());
  const emissionsText = type === 'planned' ? 'Planned emissions' : 'Telemetry emissions';

  const toggleLegCollapse = React.useCallback(
    (legIndex: number) => {
      const newSet = new Set(collapsedLegIndexes);

      if (collapsedLegIndexes.has(legIndex)) {
        newSet.delete(legIndex);
        setAllCollapsed(false);
      } else {
        newSet.add(legIndex);
        if (newSet.size === emissions?.legs.length) {
          setAllCollapsed(true);
        }
      }

      setCollapsedLegIndexes(newSet);
    },
    [collapsedLegIndexes, emissions?.legs.length],
  );

  const toggleAllCollapsing = React.useCallback(() => {
    if (allCollapsed) {
      setCollapsedLegIndexes(new Set());
    } else {
      setCollapsedLegIndexes(new Set(emissions?.legs.map((_, i) => i)));
    }
    setAllCollapsed(!allCollapsed);
  }, [allCollapsed, emissions?.legs]);

  return (
    <>
      <Table compact="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="3">{emissionsText}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row verticalAlign="top">
            <Table.Cell width={4} rowSpan={3}>
              <b>CO2 total emissions</b>
            </Table.Cell>
            <Table.Cell>Tank-to-wheel tonnes</Table.Cell>
            <Table.Cell>{emissions?.co2TankToWheelTonnes ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Well-to-tank tonnes</Table.Cell>
            <Table.Cell>{emissions?.co2WellToTankTonnes ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Well-to-wheel tonnes</Table.Cell>
            <Table.Cell>{emissions?.co2WellToWheelTonnes ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row verticalAlign="top">
            <Table.Cell rowSpan={6}>
              <b>Cargo</b>
            </Table.Cell>
            <Table.Cell>Container empty weight</Table.Cell>
            <Table.Cell>{emissions?.cargo.containerEmptyWeight ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Tons per teu</Table.Cell>
            <Table.Cell>{emissions?.cargo.tonsPerTeu ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Tons per feu</Table.Cell>
            <Table.Cell>{emissions?.cargo.tonsPerFeu ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Weight</Table.Cell>
            <Table.Cell>{emissions?.cargo.weight ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Volume weight</Table.Cell>
            <Table.Cell>{emissions?.cargo.volumeWeight ?? '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Unit</Table.Cell>
            <Table.Cell>{emissions?.cargo.unit ?? '-'}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <div className="ui list">
        {emissions?.legs.map((leg, legIdx) => (
          <React.Fragment key={legIdx}>
            {legIdx === 0 && (
              <h3>
                {`${emissionsText} legs (${emissions?.legs.length})`}
                <Button
                  floated="right"
                  compact
                  basic
                  onClick={toggleAllCollapsing}
                  icon={allCollapsed ? 'angle up' : 'angle down'}
                  size="tiny"
                />
              </h3>
            )}

            <div className="item tour__tour-details__stop-item">
              <Table structured compact size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan="3">
                      <div className="tour__tour-details__stop-item__stop-header">
                        <div className="tour__tour-details__stop-item__stop-header__address">
                          {`${emissionsText} leg #${legIdx + 1}`}
                        </div>
                        <Button
                          size="tiny"
                          compact
                          basic
                          onClick={() => toggleLegCollapse(legIdx)}
                          icon={collapsedLegIndexes.has(legIdx) ? 'angle up' : 'angle down'}
                        />
                      </div>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {!collapsedLegIndexes.has(legIdx) && (
                  <Table.Body>
                    <Table.Row verticalAlign="top">
                      <Table.Cell>
                        <b>Transport mode</b>
                      </Table.Cell>
                      <Table.Cell>{leg.transportMode}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <b>Type</b>
                      </Table.Cell>
                      <Table.Cell>{leg.type}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <b>Distance in KM</b>
                      </Table.Cell>
                      <Table.Cell>{leg.distanceKM ?? '-'}</Table.Cell>
                    </Table.Row>

                    <Table.Row verticalAlign="top">
                      <Table.Cell rowSpan={2}>
                        <b>Start location</b>
                      </Table.Cell>
                      <Table.Cell>Latitude</Table.Cell>
                      <Table.Cell>{leg.startLocation.lat}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Longitude</Table.Cell>
                      <Table.Cell>{leg.startLocation.lng}</Table.Cell>
                    </Table.Row>

                    <Table.Row verticalAlign="top">
                      <Table.Cell rowSpan={2}>
                        <b>End location</b>
                      </Table.Cell>
                      <Table.Cell>Latitude</Table.Cell>
                      <Table.Cell>{leg.endLocation.lat}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Longitude</Table.Cell>
                      <Table.Cell>{leg.endLocation.lng}</Table.Cell>
                    </Table.Row>

                    <Table.Row verticalAlign="top">
                      <Table.Cell rowSpan={7 * leg.emissions.length + 1}>
                        <b>Emissions</b>
                      </Table.Cell>
                    </Table.Row>
                    {leg.emissions.map((emission, emissionIdx) => (
                      <React.Fragment key={emissionIdx}>
                        <Table.Row>
                          <Table.Cell>
                            <b>Emission #{emissionIdx + 1}</b>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Mode</Table.Cell>
                          <Table.Cell>{emission.emissionMode}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Type</Table.Cell>
                          <Table.Cell>{emission.type}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Unit</Table.Cell>
                          <Table.Cell>{emission.unit}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Tank to wheel</Table.Cell>
                          <Table.Cell>{emission.tankToWheel}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Well to tank</Table.Cell>
                          <Table.Cell>{emission.wellToTank}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Total</Table.Cell>
                          <Table.Cell>{emission.total}</Table.Cell>
                        </Table.Row>
                      </React.Fragment>
                    ))}

                    <Table.Row verticalAlign="top">
                      <Table.Cell rowSpan={2}>
                        <b>External costs</b>
                      </Table.Cell>
                      <Table.Cell>Currency</Table.Cell>
                      <Table.Cell>{leg.externalCosts?.currency}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Total external costs</Table.Cell>
                      <Table.Cell>{leg.externalCosts?.totalExternalCosts}</Table.Cell>
                    </Table.Row>
                    {leg.externalCosts?.noiseCosts?.map((noiseCost, noiseCostIdx) => (
                      <React.Fragment key={noiseCostIdx}>
                        {noiseCostIdx === 0 && (
                          <Table.Row verticalAlign="top">
                            <Table.Cell rowSpan={3 * (leg.externalCosts?.noiseCosts?.length ?? 0) + 1}>
                              <b>External costs - noise costs</b>
                            </Table.Cell>
                          </Table.Row>
                        )}
                        <Table.Row>
                          <Table.Cell>
                            <b>Noise cost #{noiseCostIdx + 1}</b>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Transport mode</Table.Cell>
                          <Table.Cell>{noiseCost.transportMode}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Noise cost value</Table.Cell>
                          <Table.Cell>{noiseCost.noiseCostValue}</Table.Cell>
                        </Table.Row>
                      </React.Fragment>
                    ))}

                    {leg.externalCosts?.emissionCosts?.map((emissionCost, emissionCostIdx) => (
                      <React.Fragment key={emissionCostIdx}>
                        {emissionCostIdx === 0 && (
                          <Table.Row verticalAlign="top">
                            <Table.Cell rowSpan={5 * (leg.externalCosts?.emissionCosts?.length ?? 0) + 1}>
                              <b>External costs - emission costs</b>
                            </Table.Cell>
                          </Table.Row>
                        )}
                        <Table.Row>
                          <Table.Cell>
                            <b>Emission cost #{emissionCostIdx + 1}</b>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Name</Table.Cell>
                          <Table.Cell>{emissionCost.name}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Tank to wheel</Table.Cell>
                          <Table.Cell>{emissionCost.tankToWheel}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Well to tank</Table.Cell>
                          <Table.Cell>{emissionCost.wellToTank}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Total</Table.Cell>
                          <Table.Cell>{emissionCost.total}</Table.Cell>
                        </Table.Row>
                      </React.Fragment>
                    ))}

                    {leg.externalCosts?.accidentCosts.map((accidentCost, accidentCostIdx) => (
                      <React.Fragment key={accidentCostIdx}>
                        {accidentCostIdx === 0 && (
                          <Table.Row verticalAlign="top">
                            <Table.Cell rowSpan={3 * (leg.externalCosts?.accidentCosts?.length ?? 0) + 1}>
                              <b>External costs - accident costs</b>
                            </Table.Cell>
                          </Table.Row>
                        )}
                        <Table.Row>
                          <Table.Cell>
                            <b>Accident cost #{accidentCostIdx + 1}</b>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Transport mode</Table.Cell>
                          <Table.Cell>{accidentCost.transportMode}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Accident cost value</Table.Cell>
                          <Table.Cell>{accidentCost.accidentCostValue}</Table.Cell>
                        </Table.Row>
                      </React.Fragment>
                    ))}

                    <Table.Row verticalAlign="top">
                      <Table.Cell rowSpan={1}>
                        <b>Carriage parameters</b>
                      </Table.Cell>
                      <Table.Cell>Biofuel share</Table.Cell>
                      <Table.Cell>{leg.carriageParameters.bioFuelShare}</Table.Cell>
                    </Table.Row>

                    {leg.carriageParameters.carriageModeParameters?.map((carriageModeParameter, carriageMPIdx) => (
                      <React.Fragment key={carriageMPIdx}>
                        {carriageMPIdx === 0 && (
                          <Table.Row verticalAlign="top">
                            <Table.Cell rowSpan={12 * leg.carriageParameters.carriageModeParameters.length + 1}>
                              <b>Carriage mode parameters</b>
                            </Table.Cell>
                          </Table.Row>
                        )}
                        <Table.Row>
                          <Table.Cell>
                            <b>Carriage mode parameter #{carriageMPIdx + 1}</b>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Mode</Table.Cell>
                          <Table.Cell>{carriageModeParameter.mode}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Emission class</Table.Cell>
                          <Table.Cell>{carriageModeParameter.emissionClass ?? '-'}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Empty-run factor</Table.Cell>
                          <Table.Cell>{carriageModeParameter.emptyRunFactor ?? '-'}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Ferry routing</Table.Cell>
                          <Table.Cell>{carriageModeParameter.ferryRouting ?? '-'}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Fuel type</Table.Cell>
                          <Table.Cell>{carriageModeParameter.fuelType ?? '-'}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Load factor</Table.Cell>
                          <Table.Cell>{carriageModeParameter.loadFactor ?? '-'}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Lorry class</Table.Cell>
                          <Table.Cell>{carriageModeParameter.lorryClass ?? '-'}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Drive class</Table.Cell>
                          <Table.Cell>{carriageModeParameter.driveClass ?? '-'}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Aircraft type</Table.Cell>
                          <Table.Cell>{carriageModeParameter.aircraftType ?? '-'}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Seaship type</Table.Cell>
                          <Table.Cell>{carriageModeParameter.seaShipType ?? '-'}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Inland ship type</Table.Cell>
                          <Table.Cell>{carriageModeParameter.inlandShipType ?? '-'}</Table.Cell>
                        </Table.Row>
                      </React.Fragment>
                    ))}
                  </Table.Body>
                )}
              </Table>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

const FuelBasedEmissions: React.FunctionComponent<{ emissions: TourFuelBasedEmissions | null }> = ({ emissions }) => (
  <>
    <Table compact="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="3">Fuel-based emissions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Accumulated fuel consumption liters</Table.Cell>
          <Table.Cell>{emissions?.accumulatedFuelConsumptionLiters ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Fuel type</Table.Cell>
          <Table.Cell>{emissions?.fuelType ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Well-to-tank tonnes</Table.Cell>
          <Table.Cell>{emissions?.co2WellToTankTonnes ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Tank-to-wheel tonnes</Table.Cell>
          <Table.Cell>{emissions?.co2TankToWheelTonnes ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Well-to-wheel tonnes</Table.Cell>
          <Table.Cell>{emissions?.co2WellToWheelTonnes ?? '-'}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </>
);

const DrivenDistance: React.FunctionComponent<{ data: TourDrivenDistance | null }> = ({ data }) => (
  <>
    <Table compact="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="3">Driven distance</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Distance in kilometers</Table.Cell>
          <Table.Cell>{data?.distanceInKilometers ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Source</Table.Cell>
          <Table.Cell>{data?.source ?? '-'}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </>
);

const EmissionsCalculationErrors: React.FunctionComponent<{
  calculationErrors: EmissionsCalculationError[] | null;
}> = ({ calculationErrors }) => (
  <>
    <Table compact="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="3">Emissions calculation errors</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {calculationErrors?.map((error, errorIdx) => (
          <React.Fragment key={errorIdx}>
            <Table.Row verticalAlign="top">
              <Table.Cell rowSpan={2}>
                <b>Error #{errorIdx + 1}</b>
              </Table.Cell>
              <Table.Cell>Type</Table.Cell>
              <Table.Cell>{error.type ?? '-'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Code</Table.Cell>
              <Table.Cell>{error.code ?? '-'}</Table.Cell>
            </Table.Row>
          </React.Fragment>
        ))}
        {calculationErrors?.length === 0 && (
          <Table.Row>
            <Table.Cell>No errors</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  </>
);

const CustomFieldsTableItems: React.FC<{
  customFields: CustomField[];
}> = ({ customFields }) => {
  return (
    <>
      {customFields.map((customField, idx) => (
        <Table.Row key={`${customField.fieldName}_${idx}`}>
          <Table.Cell>{customField.fieldName}</Table.Cell>
          <Table.Cell>{customField.values.map(({ value }) => value).join(', ')}</Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

const DeliveriesCustomFieldsTable: React.FunctionComponent<{
  customFields: CustomField[];
}> = ({ customFields }) => {
  return (
    <>
      <Table compact="very" size="small" style={{ border: 'none' }}>
        <Table.Body>
          <Table.Row verticalAlign="top">
            <Table.Cell>Name</Table.Cell>
            <Table.Cell>Value(s)</Table.Cell>
          </Table.Row>
          <CustomFieldsTableItems customFields={customFields} />
        </Table.Body>
      </Table>
    </>
  );
};

const Deliveries: React.FunctionComponent<{ tour: Pick<Tour, 'deliveries'> }> = ({ tour: { deliveries } }) => {
  return (
    <>
      <h3>Deliveries</h3>
      {deliveries.length === 0 ? (
        <Table compact="very" size="small">
          <Table.Body>
            <Table.Row textAlign="center">
              <Table.Cell>No deliveries</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        deliveries.map((delivery) => (
          <>
            <Table compact="very" size="small" key={delivery.deliveryId}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">
                    {delivery.deliveryNumber} (ID: {delivery.deliveryId})
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={5}>
                    <b>Order number</b>
                  </Table.Cell>
                  <Table.Cell>{delivery.orderNumber ?? '-'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <b>Purchase order number</b>
                  </Table.Cell>
                  <Table.Cell>{delivery.purchaseOrderNumber ?? '-'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <b>Sequence number</b>
                  </Table.Cell>
                  <Table.Cell>{delivery.sequenceNumber ?? '-'}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    <b>Delivery tour Id</b>
                  </Table.Cell>
                  <Table.Cell>
                    {delivery.deliveryTourId !== null ? (
                      <Link to={Routes.Tour.generatePath({ tour_id: delivery.deliveryTourId })}>
                        {delivery.deliveryTourId}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <b>Main mode</b>
                  </Table.Cell>
                  <Table.Cell>{delivery.mainMode ?? '-'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <b>Storage positions</b>
                  </Table.Cell>
                  <Table.Cell>{delivery.storagePositions ?? '-'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <b>Transport unit</b>
                  </Table.Cell>
                  <Table.Cell>
                    {delivery.transportUnit?.value ?? '-'} {delivery.transportUnit?.unit}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <b>Volume</b>
                  </Table.Cell>
                  <Table.Cell>
                    {delivery.volume?.value ?? '-'} {delivery.volume?.unit}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <b>Loading length</b>
                  </Table.Cell>
                  <Table.Cell>
                    {delivery.loadingLength?.value ?? '-'} {delivery.loadingLength?.unit}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <b>Incoterms</b>
                  </Table.Cell>
                  <Table.Cell>
                    {delivery.incoterms?.city ?? '-'} {delivery.incoterms?.term}
                  </Table.Cell>
                </Table.Row>
                {delivery.customFields.length > 0 && (
                  <Table.Row>
                    <Table.Cell>
                      <b>Custom fields</b>
                    </Table.Cell>
                    <Table.Cell>
                      <DeliveriesCustomFieldsTable customFields={delivery.customFields} />
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </>
        ))
      )}
    </>
  );
};
const TourDeliveryTrees: React.FunctionComponent<{ tour: Pick<Tour, 'deliveryTree'> }> = ({
  tour: { deliveryTree },
}) => (
  <>
    <h3>Delivery tree</h3>
    {deliveryTree.length === 0 ? (
      <Table compact="very" size="small">
        <Table.Body>
          <Table.Row textAlign="center">
            <Table.Cell>No delivery tree</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    ) : (
      convertFlatDeliveryTreeToTree(deliveryTree).map((tree, idx) => <DeliveryTree tree={tree} key={idx} />)
    )}
  </>
);

function visibilityIndexStyle(index: Tour['visibilityIndex']): React.CSSProperties {
  if (index === null) {
    return {};
  }

  const hue = (index * 120).toString(10);

  return {
    textDecoration: `underline 2px solid hsl(${hue},60%,50%)`,
  };
}

/**
 *
 * Utility components
 *
 */

const VehicleInfo: React.StatelessComponent<{
  vehicle: { vehicleId: string | null; vehicleLicensePlateNumber: string } | null;
}> = ({ vehicle }) => {
  const vehicleId = vehicle?.vehicleId ?? null;
  const vehicleLicensePlateNumber = vehicle?.vehicleLicensePlateNumber ?? null;

  return vehicleId !== null && vehicleLicensePlateNumber !== null ? (
    <Link to={Routes.Vehicle.generatePath({ vehicle_id: vehicleId })}>{vehicleLicensePlateNumber}</Link>
  ) : vehicleId !== null ? (
    <Link to={Routes.Vehicle.generatePath({ vehicle_id: vehicleId })}>
      <i>ID #{vehicleId}</i>
    </Link>
  ) : vehicleLicensePlateNumber !== null ? (
    <>{vehicleLicensePlateNumber} (No vehicle ID)</>
  ) : (
    <>-</>
  );
};

const StopETADetails: React.FunctionComponent<{ stop: TourStop; tourId: number }> = ({ stop, tourId }) => {
  const hasAltEtas = stop.etas !== null && stop.etas.length > 0;
  const [showAltEtas, setShowAltEtas] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <Table structured basic compact size="small">
        <ETADetails stop={stop} title="ETA" tourId={tourId} />
        {showAltEtas
          ? (stop.etas ?? []).map((eta, i) => {
              return <ETADetails stop={eta} title={`Additional ETA #${i + 1}`} key={i} tourId={tourId} />;
            })
          : null}
        {hasAltEtas && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                <Button size="mini" onClick={() => setShowAltEtas(!showAltEtas)}>
                  {showAltEtas ? 'Hide additional ETAs' : `Show other ETAs (${stop.etas?.length ?? 0})`}
                </Button>
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </React.Fragment>
  );
};

const ETADetails: React.FunctionComponent<{
  stop: TourStopEta;
  title: React.ReactNode;
  tourId: number;
}> = ({ stop, title, tourId }) => {
  const { etaSource, etaType } = getEtaSourceAndType(stop);
  return (
    <Table.Body>
      <Table.Row verticalAlign="top">
        <Table.Cell
          width={3}
          rowSpan={getRowSpan(
            stop,
            8,
            null,
            'estimated_arrival_unregulated',
            'estimated_arrival_regulated',
            'estimated_arrival_accuracy',
            'estimated_arrival_distance_in_meters',
            'estimated_arrival',
            'estimated_arrival_status',
          )}>
          <b>{title}</b>
        </Table.Cell>
        <Table.Cell width={5}>ETA source(TYPE)</Table.Cell>
        <Table.Cell>
          {etaSource} {etaType !== undefined ? `(${etaType})` : null}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>ETA history</Table.Cell>
        <Table.Cell>
          <a
            className="link"
            rel="noopener noreferrer"
            target="_blank"
            title="Stop ETA report"
            href={tourStopEtaHistoryUrl({
              tourId,
              stopId: stop.stop_id,
            })}>
            Data studio ETA report
          </a>
        </Table.Cell>
      </Table.Row>
      {stop.estimated_arrival != null && (
        <Table.Row>
          <Table.Cell>ETA</Table.Cell>
          <Table.Cell>
            <FormattedDate date={stop.estimated_arrival} />
          </Table.Cell>
        </Table.Row>
      )}
      {stop.estimated_arrival_unregulated !== null && (
        <Table.Row>
          <Table.Cell>ETA (without breaks)</Table.Cell>
          <Table.Cell>
            <FormattedDate date={stop.estimated_arrival_unregulated} />
          </Table.Cell>
        </Table.Row>
      )}
      {stop.estimated_arrival_regulated !== null && (
        <Table.Row>
          <Table.Cell>ETA (with breaks)</Table.Cell>
          <Table.Cell>
            <FormattedDate date={stop.estimated_arrival_regulated} />
          </Table.Cell>
        </Table.Row>
      )}
      {stop.estimated_arrival_status != null && (
        <Table.Row>
          <Table.Cell>Status</Table.Cell>
          <Table.Cell>{stop.estimated_arrival_status}</Table.Cell>
        </Table.Row>
      )}
      {stop.estimated_arrival_accuracy != null && (
        <Table.Row>
          <Table.Cell>Accuracy</Table.Cell>
          <Table.Cell>{stop.estimated_arrival_accuracy}</Table.Cell>
        </Table.Row>
      )}
      {stop.estimated_arrival_distance_in_meters !== null && (
        <Table.Row>
          <Table.Cell>Remaining distance</Table.Cell>
          <Table.Cell>
            {
              <>
                {(stop.estimated_arrival_distance_in_meters / 1000).toFixed(0)}
                km ({stop.estimated_arrival_distance_in_meters}
                m)
              </>
            }
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  );
};

function getEtaSourceAndType(stop: TourStopEta): {
  etaSource: EtaType | undefined;
  etaType: EtaType | ExternalEtaSource | undefined;
} {
  if (stop.etaType === EtaType.ML_MODEL) {
    return { etaSource: EtaType.INTERNAL, etaType: stop.etaType };
  }
  if (stop.externalEtaSource !== null) {
    return { etaSource: EtaType.EXTERNAL, etaType: stop.externalEtaSource };
  }
  return { etaSource: stop.etaType ?? undefined, etaType: undefined };
}

const AllocationDetails: React.FunctionComponent<{ allocation: TourVehicleAllocation }> = ({ allocation }) => {
  if (allocation.allocationType === TourVehicleAllocationType.STOP_ALLOCATION) {
    return (
      <React.Fragment>
        {(allocation.stopAllocations ?? []).map((stopAllocation, idx) => {
          if (stopAllocation.vehicleId !== null && stopAllocation.vehicleLicensePlateNumber !== null) {
            return (
              <span style={{ marginRight: 4 }} key={idx}>
                <Link to={Routes.Vehicle.generatePath({ vehicle_id: stopAllocation.vehicleId })}>
                  {stopAllocation.vehicleLicensePlateNumber}
                </Link>{' '}
                (#
                {stopAllocation.stopId})
              </span>
            );
          }

          if (stopAllocation.vehicleLicensePlateNumber !== null) {
            return (
              <span style={{ marginRight: 4 }} key={idx}>
                {stopAllocation.vehicleLicensePlateNumber} (#
                {stopAllocation.stopId})
              </span>
            );
          }

          if (allocation.remoteTrackerId !== null) {
            return <React.Fragment key={idx}>{allocation.remoteTrackerId}</React.Fragment>;
          }

          return <React.Fragment key={idx}>Unallocated</React.Fragment>;
        })}
      </React.Fragment>
    );
  }

  const licensePlateOrTrackerId = allocation.vehicleLicensePlateNumber ?? allocation.remoteTrackerId;

  if (allocation.source === TourVehicleAllocationSource.TOUR_BASED_STREAM) {
    return (
      <>
        {allocation.vehicleId}
        {licensePlateOrTrackerId !== null ? ` (${licensePlateOrTrackerId})` : ''}
      </>
    );
  }

  if (allocation.vehicleLicensePlateNumber !== null && allocation.vehicleId !== null) {
    return (
      <Link to={Routes.Vehicle.generatePath({ vehicle_id: allocation.vehicleId })}>
        {allocation.vehicleLicensePlateNumber}
      </Link>
    );
  }

  if (licensePlateOrTrackerId !== null) {
    return <React.Fragment>{licensePlateOrTrackerId}</React.Fragment>;
  }

  if (allocation.clearAllocation === true) {
    return <React.Fragment>Allocation cleared</React.Fragment>;
  }

  return <React.Fragment>Unallocated</React.Fragment>;
};

const DeliveryTree: React.FunctionComponent<{ tree: TourDeliveryTree }> = ({ tree }) => {
  const [isChildDeliveriesVisible, setIsChildDeliveriesVisible] = React.useState(false);

  return (
    <Table structured compact size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="3">
            <i>
              {tree.tourId !== null ? (
                <Link to={Routes.Tour.generatePath({ tour_id: tree.tourId })}>Tour #{tree.tourId}</Link>
              ) : (
                <>#{tree.deliveryTreeId}</>
              )}
            </i>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row verticalAlign="top">
          <Table.Cell width={3} rowSpan={4}>
            <b>IDs</b>
          </Table.Cell>
          <Table.Cell width={5}>Delivery tree ID</Table.Cell>
          <Table.Cell>{tree.deliveryTreeId}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Platform ID</Table.Cell>
          <Table.Cell>{tree.platformId}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Platform delivery ID</Table.Cell>
          <Table.Cell>{tree.platformDeliveryId}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Platform previous delivery ID</Table.Cell>
          <Table.Cell>{tree.platformPreviousDeliveryId ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row verticalAlign="top">
          <Table.Cell rowSpan={3}>
            <b>Delivery</b>
          </Table.Cell>
          <Table.Cell>Delivery number</Table.Cell>
          <Table.Cell>{tree.deliveryNumber ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Order number</Table.Cell>
          <Table.Cell>{tree.orderNumber ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Purchase order number</Table.Cell>
          <Table.Cell>{tree.purchaseOrderNumber ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row verticalAlign="top">
          <Table.Cell rowSpan={2}>
            <b>Sequence</b>
          </Table.Cell>
          <Table.Cell>Sequence number</Table.Cell>
          <Table.Cell>{tree.sequenceNumber}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Platform sequence number</Table.Cell>
          <Table.Cell>{tree.platformSequenceNumber ?? '-'}</Table.Cell>
        </Table.Row>
        <Table.Row verticalAlign="top">
          <Table.Cell rowSpan={2}>
            <b>Stops</b>
          </Table.Cell>
          <Table.Cell>Loading stop</Table.Cell>
          <Table.Cell>{tree.loadingStop !== null ? <div>#{tree.loadingStop.stopId}</div> : '-'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Unloading stop</Table.Cell>
          <Table.Cell>{tree.unloadingStop !== null ? <div>#{tree.unloadingStop.stopId}</div> : '-'}</Table.Cell>
        </Table.Row>
        <Table.Row verticalAlign="top">
          <Table.Cell rowSpan={Object.keys(tree.customFields).length + 1}>
            <b>Custom fields</b>
          </Table.Cell>
          <Table.Cell colSpan="2">
            {Object.keys(tree.customFields).length === 0 ? (
              'No custom fields'
            ) : (
              <>{Object.keys(tree.customFields).length} fields</>
            )}
          </Table.Cell>
        </Table.Row>
        {Object.keys(tree.customFields).map((key, idx) => (
          <Table.Row key={idx}>
            <Table.Cell>{key}</Table.Cell>
            <Table.Cell>{tree.customFields[key].value}</Table.Cell>
          </Table.Row>
        ))}

        <Table.Row verticalAlign="top">
          <Table.Cell rowSpan={isChildDeliveriesVisible ? 2 : 1}>
            <b>Children</b>
          </Table.Cell>
          <Table.Cell colSpan="2">
            {tree.childDeliveries.length > 0 ? (
              <Button
                size="mini"
                compact
                onClick={() => setIsChildDeliveriesVisible((previousState) => !previousState)}>
                {isChildDeliveriesVisible ? 'Hide' : 'Show'}
              </Button>
            ) : (
              'No child deliveries'
            )}
          </Table.Cell>
        </Table.Row>
        {isChildDeliveriesVisible && (
          <Table.Row>
            <Table.Cell colSpan="2">
              {tree.childDeliveries.map((child) => (
                <DeliveryTree tree={child} key={`${child.deliveryTreeId}`} />
              ))}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

const TourHeaderButtons: React.FunctionComponent<{ tour: Tour } & TourDetailsViewProps> = ({
  tour,
  onTourAbort,
  onTourRecalculate,
  onAddTourTag,
  onRemoveTourTag,
}) => {
  const isNotAdmin = !useIsAdmin();
  const hasTourEditPermission = useHasScopes([BackofficeScope.companyWriteTours]);
  const isPrivilegedUser = !isNotAdmin || hasTourEditPermission;
  const [abortState, setAbortState] = React.useState<boolean>(false);
  const [recalcState, setRecalcState] = React.useState<boolean>(false);

  const updateAbortState = (newState: boolean): void => {
    setAbortState(newState);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    newState ? setRecalcState(false) : null;
  };
  const updateRecalcState = (newState: boolean): void => {
    setRecalcState(newState);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    newState ? setAbortState(false) : null;
  };
  return (
    <div style={{ display: 'inline-block' }}>
      <span style={{ verticalAlign: 'middle', marginRight: 3 }} className="ui large header">
        {' '}
        Tour #{tour.tour_id}
      </span>
      <TagsInput
        tags={tour.tags}
        availableTags={tour.availableTourTags}
        addTag={(tagName: string) =>
          onAddTourTag({
            tourId: tour.tour_id.toString(),
            tagName,
          })
        }
        removeTag={(tagName: string) => onRemoveTourTag({ tourId: tour.tour_id.toString(), tagName })}
      />
      <ConfirmButton
        size="mini"
        label="Abort"
        showConfirmationProp={abortState}
        updateConfirmState={updateAbortState}
        disabled={!isPrivilegedUser}
        onConfirm={() => onTourAbort(tour.tour_id)}
      />
      <ConfirmButton
        size="mini"
        label="Recalc"
        showConfirmationProp={recalcState}
        updateConfirmState={updateRecalcState}
        disabled={!isPrivilegedUser}
        onConfirm={() => onTourRecalculate(tour.tour_id)}
      />
    </div>
  );
};
