import { Button, Icon, Stack, Tooltip } from '@sixfold/common-ui';
import { Table } from '@sixfold/table-component';
import { notNil } from '@sixfold/typed-primitives';
import { subDays } from 'date-fns';
import { DateTime as LDateTime } from 'luxon';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { emptyTableState, getTableHeaderClassNames } from '../../components/table';
import { DateTime, EndpointUsageCounterByDate } from '../../lib/graphql';
import { Routes } from '../../routes';

export type ApiEndpointUsageLogInList = {
  api_implemented_at: DateTime | null;
  counters: EndpointUsageCounterByDate[];
  endpoint_id: string;
  first_call_at: DateTime;
  last_call_at: DateTime;
  integration_identifier: string;
};

export type ApiEndpointUsageLogsListProps = {
  data: {
    company_id: string;
    company_name: string | null;
    api_endpoint_usage_logs_data: {
      api_endpoint_usage_logs: ApiEndpointUsageLogInList[];
      shipper_tours_count?: number;
      carrier_tours_count?: number;
    };
  }[];
  isSingleCompany: boolean;
  status?: string;
};

export enum ImplementationStatus {
  Implemented = 'Implemented',
  InactiveMoreThan30Days = 'Inactive more than 30 days',
  UnderImplementation = 'Under implementation',
}
export const apiEndpointIdsMap = new Map<string, string>([
  ['tp-rtv-api-post-transport-status', 'POST /transport/status'],
  ['tp-rtv-api-post-allocation', 'POST /transports/{identifier_type}/{identifier_value}/allocation'],
]);

type MapDataByEndpointUsageLog = ApiEndpointUsageLogInList & {
  company_id: string;
  company_name: string;
  implementation_status: ImplementationStatus;
};

class ApiEndpointUsageLogTable extends Table<MapDataByEndpointUsageLog> {}
class EndpointUsageLogTable extends Table<{ date: string; count: number }> {}

export const ApiEndpointUsageLogsList: React.FunctionComponent<ApiEndpointUsageLogsListProps> = ({
  data,
  isSingleCompany,
  status, // Filter by implementation status
}) => {
  const [expandedRows, setExpandedRows] = useState(new Set());

  const handleExpandClick = (companyId: string, endpointId: string, integrationIdentifier: string) => {
    setExpandedRows((currentState) => {
      const rowId = `${companyId}:${endpointId}:${integrationIdentifier}`;
      const newExpandedRows = new Set(currentState);
      if (newExpandedRows.has(rowId)) {
        newExpandedRows.delete(rowId);
      } else {
        newExpandedRows.add(rowId);
      }
      return newExpandedRows;
    });
  };

  const apiEndpointUsageLogsTableHeaderColumns: { keyPath: string; value: string | JSX.Element }[] = [
    // Display company column only if we are NOT in single company view
    !isSingleCompany
      ? {
          keyPath: 'company_id',
          value: 'Company',
        }
      : undefined,
    {
      keyPath: 'endpoint_id',
      value: 'Endpoint',
    },
    {
      keyPath: 'integration_identifier',
      value: 'Source',
    },
    {
      keyPath: 'first_call_at',
      value: 'First call at',
    },
    {
      keyPath: 'last_call_at',
      value: 'Last call at',
    },
    {
      keyPath: 'implementation_status',
      value: (
        <div>
          <Tooltip
            content={
              <>
                <p>A date of completion is calculated when one of the following 2 condition is met:</p>
                <p>- Streak: 7 consecutive days of use. The last of this day would be used as a completion date.</p>
                <p>
                  - Regularity: more than 15 days of use within a 21-day period. The last of this day would be used as a
                  completion date.
                </p>
              </>
            }
            placement="top">
            <Stack alignItems="center">
              Implementation status
              <Icon name="help" />
            </Stack>
          </Tooltip>
        </div>
      ),
    },
  ].filter(notNil);

  // We received data in the form of an array of companies (each containing an array of api_endpoint_usage_logs) -> The data is structured by company.
  // Now we restructure it to have an array of api_endpoint_usage logs (each including the associated company) -> The data will be structured by api_endpoint_usage_log.
  // This way we can display the data in a table where each row represents an api_endpoint_usage_log.
  const mappedData: MapDataByEndpointUsageLog[] = data
    .map((company) => {
      return company.api_endpoint_usage_logs_data.api_endpoint_usage_logs?.map((apiEndpointUsageLog) => {
        const lastCallDate = new Date(apiEndpointUsageLog.last_call_at);
        const implementationStatus = apiEndpointUsageLog.api_implemented_at
          ? ImplementationStatus.Implemented
          : subDays(new Date(), 30) > lastCallDate
            ? ImplementationStatus.InactiveMoreThan30Days
            : ImplementationStatus.UnderImplementation;
        return {
          company_id: company.company_id,
          company_name: company.company_name ?? company.company_id,
          implementation_status: implementationStatus,
          ...apiEndpointUsageLog,
        };
      });
    })
    .filter(notNil)
    .flat()
    .filter((log) => !status || log.implementation_status === status);

  return (
    <>
      <div>
        {isSingleCompany && // Display tours count only if we are in single company view
          notNil(data[0].api_endpoint_usage_logs_data.shipper_tours_count) &&
          notNil(data[0].api_endpoint_usage_logs_data.carrier_tours_count) && (
            <>
              {data[0].api_endpoint_usage_logs_data.shipper_tours_count === 0 ||
              data[0].api_endpoint_usage_logs_data.carrier_tours_count === 0 ? (
                <p>
                  Tours count:{' '}
                  {data[0].api_endpoint_usage_logs_data.shipper_tours_count +
                    data[0].api_endpoint_usage_logs_data.carrier_tours_count}
                </p>
              ) : (
                <>
                  <p>Tours count as a shipper: {data[0].api_endpoint_usage_logs_data.shipper_tours_count}</p>
                  <p>Tours count as a carrier: {data[0].api_endpoint_usage_logs_data.carrier_tours_count}</p>
                </>
              )}
            </>
          )}
        <ApiEndpointUsageLogTable
          className="ui very basic sortable unstackable table"
          tableHeaders={{
            defaultClassName: getTableHeaderClassNames,
            columns: apiEndpointUsageLogsTableHeaderColumns,
          }}
          data={mappedData}
          defaultSortBy={{ keyPath: 'endpointId', value: 'ASC' }}
          emptyStatePlaceholder={emptyTableState('Visibility API is not in use')}>
          {({ row }) => {
            const {
              integration_identifier,
              endpoint_id,
              api_implemented_at,
              counters,
              first_call_at,
              last_call_at,
              company_id,
              company_name,
              implementation_status,
            } = row.data;

            const sortedCounters = counters.sort((a, b) => a.logDate.localeCompare(b.logDate));
            const groupedByDates: Map<string, number> = new Map<string, number>();
            // Counts are stored by statusCode and logDate pairs, we need to group them by logDate and add up the noOfCalls for each date
            for (const counter of sortedCounters) {
              const date = LDateTime.fromJSDate(new Date(counter.logDate), { zone: 'utc' }).toFormat('dd.MM');
              const count = groupedByDates.get(date);
              if (count !== undefined) {
                groupedByDates.set(date, count + counter.noOfCalls);
              } else {
                groupedByDates.set(date, counter.noOfCalls);
              }
            }
            const firstCallDate = new Date(first_call_at);
            const lastCallDate = new Date(last_call_at);

            const datesAndCounts = Array.from(groupedByDates.entries())
              .map(([date, count]) => ({ date, count }))
              .slice(-21);

            const apiEndpointUsageLogsTableHeaderColumns: { keyPath?: string; value: string }[] = datesAndCounts
              .map((item) => ({
                keyPath: item.date,
                value: item.date,
              }))
              .filter(notNil);

            if (api_implemented_at !== null) {
              apiEndpointUsageLogsTableHeaderColumns.push({
                keyPath: 'api_implemented_at',
                value: 'API implementation completion date',
              });
            }

            return (
              <React.Fragment key={`${company_id}:${endpoint_id}:${integration_identifier}`}>
                <tr key={`${company_id}:${endpoint_id}:${integration_identifier}`}>
                  {!isSingleCompany ? ( // Display company column only if we are NOT in single company view
                    <td>
                      <NavLink to={Routes.Company.generatePath({ company_id })}>{company_name ?? company_id}</NavLink>
                    </td>
                  ) : undefined}
                  <td>{apiEndpointIdsMap.get(endpoint_id) ?? endpoint_id}</td>
                  <td>{integration_identifier}</td>
                  <td>
                    <FormattedDate date={firstCallDate} />
                  </td>
                  <td>
                    <FormattedDate date={lastCallDate} />
                  </td>
                  <td>
                    {implementation_status === ImplementationStatus.Implemented ? (
                      <Stack alignItems="center">
                        <Icon name="check" kind="success" />
                        {ImplementationStatus.Implemented}
                      </Stack>
                    ) : implementation_status === ImplementationStatus.InactiveMoreThan30Days ? (
                      <Stack alignItems="center">
                        <Icon name="small-triangular-alert" kind="warning" />
                        {ImplementationStatus.InactiveMoreThan30Days}
                      </Stack>
                    ) : implementation_status === ImplementationStatus.UnderImplementation ? (
                      <Stack alignItems="center">
                        <Icon name="small-rocket" kind="highlight" />
                        {ImplementationStatus.UnderImplementation}
                      </Stack>
                    ) : (
                      'Unknown' // Should never happen because implementationStatus is always defined
                    )}
                  </td>
                  <td className="right aligned">
                    {expandedRows.has(`${company_id}:${endpoint_id}:${integration_identifier}`) ? (
                      <Button
                        hideLabel
                        iconStart="small-caret-down"
                        size="xsmall"
                        onClick={() => handleExpandClick(company_id, endpoint_id, integration_identifier)}>
                        Collapse
                      </Button>
                    ) : (
                      <Button
                        hideLabel
                        iconStart="small-caret-right"
                        size="xsmall"
                        onClick={() => handleExpandClick(company_id, endpoint_id, integration_identifier)}>
                        Expand
                      </Button>
                    )}
                  </td>
                </tr>
                {expandedRows.has(`${company_id}:${endpoint_id}:${integration_identifier}`) && (
                  <td colSpan={isSingleCompany ? 5 : 6}>
                    <div
                      style={{
                        backgroundColor: '#F7F7F7',
                        overflow: 'auto',
                        maxWidth: 'calc(100vw - 100px)',
                        maxHeight: '300px',
                        padding: '20px',
                      }}>
                      <tr>
                        <EndpointUsageLogTable
                          className="ui very basic sortable unstackable table"
                          tableHeaders={{
                            defaultClassName: getTableHeaderClassNames,
                            columns: apiEndpointUsageLogsTableHeaderColumns,
                          }}
                          data={datesAndCounts}
                          emptyStatePlaceholder={emptyTableState('No endpoint usage logs in the past 21 days')}>
                          {({ row }) => {
                            return (
                              <>
                                <td key={row.data.date}>{row.data.count}</td>
                                {api_implemented_at !== null && row.index === datesAndCounts.length - 1 && (
                                  <td>
                                    <FormattedDate date={api_implemented_at} />
                                  </td>
                                )}
                              </>
                            );
                          }}
                        </EndpointUsageLogTable>
                      </tr>
                    </div>
                  </td>
                )}
              </React.Fragment>
            );
          }}
        </ApiEndpointUsageLogTable>
      </div>
    </>
  );
};
